import { FaBars } from "react-icons/fa"
import { NavLink as Link } from "react-router-dom"
import styled from "styled-components"

export const NavControllerStyled = styled.span`
  #mobile-nav {
    display: none;
  }

  @media screen and (max-width: 768px) {
    #desktop-nav {
      display: none;
    }

    #mobile-nav {
      display: flex;
    }
  }
`

export const Nav = styled.nav`
  width: calc(100% - 120px);
  height: 85px;
  display: flex;
  justify-content: space-between;
  z-index: 12;
  padding: 0 60px;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 20px;
  line-height: 24px;
  height: 100%;
  font-weight: 300;
  color: ${(props) => (props.isbelongtoproject ? "#216583" : "#00818a")};
  font-weight: ${(props) => (props.isbelongtoproject ? 900 : "normal")};
  cursor: pointer;
  &.active {
    color: #216583;
    font-weight: 900;
  }
  &:hover {
    color: #216583;
  }
  @media screen and (max-width: 768px) {
    display: block;
    padding: 0;
  }
  margin-left: ${(props) => (props.ml ? `${props.ml}px` : "0")};
`

export const Bars = styled(FaBars)`
  display: block;
  color: #00818a;
  position: absolute;
  top: 7px;
  right: 0;
  transform: translate(-100%, 75%);
  font-size: 1.8rem;
  cursor: pointer;
`

export const NavMobileMenu = styled.div`
  width: 100%;
  position: absolute;
  text-align: center;
  margin-top: 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  gap: 40px;
  z-index: ${(props) => (props.active ? 12 : -1)};
  top: ${(props) => (props.active ? 0 : `calc("-100%" - "85px")`)};
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: all 0.35s ease;
  padding: 25px 0 25px 0;
`

export const Blur = styled.div`
  position: absolute;
  width: 100vw;
  width: 100vh;
  filter: blur(1px) !important;
  z-index: 5;
`

export const LogoMobileWrapper = styled.div`
  display: flex;
`

export const PieMobileNavNameStyled = styled.div`
  height: 85px;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  color: #00818a;
  padding: 30px 0 0 30px;
`
