import React from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import {
  ContentTextNormal,
  BackIconWrapper,
  ProjectTextTitle,
  ContentTextBold,
  ProjectImageWrapper,
  ProjectDetailContainerStyled,
  FullImageWrapper,
  Gap,
} from "../../../components/shared/shared"

import backIcon from "../../../img/back-icon.svg"
import athenaPosterImage from "../../../img/athena/athena-poster-v2.jpg"
import athenaProcessImage from "../../../img/athena/summary-of-athena-process.png"

const ContentWrapper = styled.div`
  width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 140px);
    padding: 0 70px;
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 52px);
    padding: 0 27px;
  }
`

const Athena = () => {
  const navigate = useNavigate()

  return (
    <>
      <BackIconWrapper onClick={() => navigate(-1)}>
        <img src={backIcon} width={8} height={15} alt="Back Icon" />
        <ContentTextNormal>Projects</ContentTextNormal>
      </BackIconWrapper>
      <ProjectDetailContainerStyled>
        <ContentWrapper>
          <ProjectTextTitle>Athena | 2020</ProjectTextTitle>
          <ContentTextNormal>
            This is a research-based project that focuses around the technology, artificial intelligence, and assistant.
            Athena is an ideal assistant that helps build users’ confident and improve their public speaking.
          </ContentTextNormal>
          <ContentTextBold>
            Project Contributors: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>
              Yonqifang Hu, Jun Zhao, and special advisor, Professor Erik Stolterman of the Indiana University
            </ContentTextNormal>
          </ContentTextBold>
          <ContentTextBold>Summary of my process:</ContentTextBold>
          <ProjectImageWrapper src={athenaProcessImage} alt="Athena's process" />
        </ContentWrapper>
        <Gap height="50" />
        <FullImageWrapper>
          <ProjectImageWrapper src={athenaPosterImage} alt="Athena Poster" />
        </FullImageWrapper>
      </ProjectDetailContainerStyled>
    </>
  )
}

export default Athena
