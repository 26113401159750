import React from "react"
import styled from "styled-components"

import { TitleNormal } from "../../components/shared/shared"

export const ContainerStyled = styled.div`
  width: 100%;
  height: calc(100vh - 85px);
  display: flex;
  justify-content: center;
  align-items: center;
`

const NotFound = () => {
  return (
    <ContainerStyled>
      <TitleNormal mWidth="80%" mCenter>
        You have landed on a page that doesn't exist.
      </TitleNormal>
    </ContainerStyled>
  )
}

export default NotFound
