import React from "react"

import { ContentWrapper, PieFaceImageWrapper, PieNameText, HelloText } from "../../components/about/v2-styles"
import {
  PieNameStyled,
  PieExpStyled,
  RentSpreeLinkStyled,
  LookingForStyled,
  DownloadResumeContactStyled,
  ReachOutTomeStyled,
  ContactDetailWrapper,
  ContactWayStyled,
} from "../../components/contact/v2-styles"
import { ContentTextNormal, ContentTextNormalLink } from "../../components/shared/shared"
import { PieFaceAnimationWrapper } from "../../components/contact/v2-animation-styles"

import PieFaceImage from "../../img/about-v2/pie-face-image.png"
import DownloadIcon from "../../img/download-icon.svg"

import mailIcon from "../../img/contact/mail-icon.svg"
import mobileIcon from "../../img/contact/mobile-icon.svg"
import wwwIcon from "../../img/contact/www-icon.svg"
import linkedInIcon from "../../img/contact/linkedin-icon.svg"
import behanceIcon from "../../img/contact/behance-icon.svg"

import pieResumePdf from "../../resources/pie-prapawuttikul-resume-01-sept-23.pdf"

const ANIMATION_IDS = ["reach-out-to-me", "pie-contact-ways"]

const ANIMATION_POSITIONS = {
  "reach-out-to-me": 18,
  "pie-contact-ways": 26,
}

const AboutV2 = () => {
  const animation = () => {
    for (var i = 0; i < ANIMATION_IDS.length; i++) {
      var component = document.getElementById(ANIMATION_IDS[i])
      var windowHeight = window.innerHeight
      var elementTop = component.getBoundingClientRect().top
      var elementVisible = ANIMATION_POSITIONS[ANIMATION_IDS[i]]
      if (i === 1) {
        console.log("windowHeight ", windowHeight)
        console.log("elementTop ", elementTop)
        console.log("elementTop < windowHeight - elementVisible ", elementTop < windowHeight - elementVisible)
      }
      if (elementTop < windowHeight - elementVisible) {
        component.classList.add("animate")
      } else {
        component.classList.remove("animate")
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", animation)
    return () => {
      window.removeEventListener("scroll", animation)
    }
  }, [])

  const MobileComponent = () => (
    <ContentWrapper showMobile hideDesktop mPadding="0 45px">
      <HelloText id="hello-text">Hello!</HelloText>
      <PieNameText>My name is Pie :)</PieNameText>
      <PieFaceImageWrapper mt="0" src={PieFaceImage} alt="Pie's Face Image" />
      <PieExpStyled>
        {/* Formerly a Product Designer at{" "}
        <RentSpreeLinkStyled href="https://www.rentspree.com/" target="_blank">
          RentSpree
        </RentSpreeLinkStyled>
        , currently a fresh Service Design graduate :) */}
        Currently a Service Designer at{" "}
        <RentSpreeLinkStyled href="https://mediabarninc.com/" target="_blank">
          Mediabarn Inc.
        </RentSpreeLinkStyled>
      </PieExpStyled>
      {/* <LookingForStyled>
        I am looking for awesome job opportunity in product design, service design, or ux/ui design
      </LookingForStyled> */}
      <DownloadResumeContactStyled id="download-resume-text" mt="20" href={pieResumePdf} target="_blank">
        <img src={DownloadIcon} alt="Download Icon" /> Download my resume
      </DownloadResumeContactStyled>
      <ReachOutTomeStyled>Reach out to me ;)</ReachOutTomeStyled>
      <ContactWayStyled>
        <ContactDetailWrapper>
          <img src={mailIcon} alt="Mail Icon" />
          <ContentTextNormalLink target="_blank" href="mailto: pirpiep@gmail.com">
            pirpiep@gmail.com
          </ContentTextNormalLink>
        </ContactDetailWrapper>
        <ContactDetailWrapper gap="12">
          <img src={mobileIcon} alt="Mobile Icon" style={{ paddingLeft: "3px" }} />
          <ContentTextNormal>(626) 734 5297</ContentTextNormal>
        </ContactDetailWrapper>
        <ContactDetailWrapper>
          <img src={wwwIcon} alt="Website Icon" />
          <ContentTextNormalLink href="https://www.pirpie.com">www.pirpie.com</ContentTextNormalLink>
        </ContactDetailWrapper>
        <ContactDetailWrapper>
          <img src={linkedInIcon} alt="LinkedIn Icon" />
          <ContentTextNormalLink target="_blank" href="https://www.linkedin.com/in/pirpie">
            linkedin.com/in/pirpie
          </ContentTextNormalLink>
        </ContactDetailWrapper>
        <ContactDetailWrapper>
          <img src={behanceIcon} alt="Behance Icon" />
          <ContentTextNormalLink target="_blank" href="https://www.behance.net/pirpiep">
            behance.net/pirpiep
          </ContentTextNormalLink>
        </ContactDetailWrapper>
      </ContactWayStyled>
    </ContentWrapper>
  )

  const DesktopComponent = () => (
    <ContentWrapper hideMobile>
      <PieFaceAnimationWrapper isAnimateDesktop>
        <PieFaceImageWrapper mt="0" src={PieFaceImage} alt="Pie's Face Image" />
      </PieFaceAnimationWrapper>
      <PieNameStyled isAnimateDesktop>My name is Pie :)</PieNameStyled>
      <PieExpStyled isAnimateDesktop>
        {/* Formerly a Product Designer at{" "}
        <RentSpreeLinkStyled href="https://www.rentspree.com/" target="_blank">
          RentSpree
        </RentSpreeLinkStyled>
        , currently a fresh Service Design graduate :) */}
        Currently a Service Designer at{" "}
        <RentSpreeLinkStyled href="https://mediabarninc.com/" target="_blank">
          Mediabarn Inc.
        </RentSpreeLinkStyled>
      </PieExpStyled>
      {/* <LookingForStyled isAnimateDesktop>
        I am looking for awesome job opportunity in product design, service design, or ux/ui design
      </LookingForStyled> */}
      <DownloadResumeContactStyled
        id="download-resume-text"
        isAnimateDesktop
        mt="20"
        href={pieResumePdf}
        target="_blank">
        <img src={DownloadIcon} alt="Download Icon" /> Download my resume
      </DownloadResumeContactStyled>
      <ReachOutTomeStyled id="reach-out-to-me" isAnimateDesktop>
        Reach out to me ;)
      </ReachOutTomeStyled>
      <ContactWayStyled id="pie-contact-ways" isAnimateDesktop>
        <ContactDetailWrapper>
          <img src={mailIcon} alt="Mail Icon" />
          <ContentTextNormalLink target="_blank" href="mailto: pirpiep@gmail.com">
            pirpiep@gmail.com
          </ContentTextNormalLink>
        </ContactDetailWrapper>
        <ContactDetailWrapper gap="12">
          <img src={mobileIcon} alt="Mobile Icon" style={{ paddingLeft: "3px" }} />
          <ContentTextNormal>(626) 734 5297</ContentTextNormal>
        </ContactDetailWrapper>
        <ContactDetailWrapper>
          <img src={wwwIcon} alt="Website Icon" />
          <ContentTextNormalLink href="https://www.pirpie.com">www.pirpie.com</ContentTextNormalLink>
        </ContactDetailWrapper>
        <ContactDetailWrapper>
          <img src={linkedInIcon} alt="LinkedIn Icon" />
          <ContentTextNormalLink target="_blank" href="https://www.linkedin.com/in/pirpie">
            linkedin.com/in/pirpie
          </ContentTextNormalLink>
        </ContactDetailWrapper>
        <ContactDetailWrapper>
          <img src={behanceIcon} alt="Behance Icon" />
          <ContentTextNormalLink target="_blank" href="https://www.behance.net/pirpiep">
            behance.net/pirpiep
          </ContentTextNormalLink>
        </ContactDetailWrapper>
      </ContactWayStyled>
    </ContentWrapper>
  )

  return (
    <>
      <DesktopComponent />
      <MobileComponent />
    </>
  )
}

export default AboutV2
