import React from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import {
  ContentTextNormal,
  BackIconWrapper,
  ProjectTextTitleV2,
  ProjectSubTitleV2,
  ContentTextBold,
  ProjectImageWrapper,
  ProjectDetailContainerStyled,
  SectionTitleV2,
  OutsideImageWrapper,
  BulletWrapper,
  ClickableSectionTitleV2,
  OutsideImageWrapperLink,
} from "../../../components/shared/shared"
import { TagWrapper, Tag } from "../../../components/tag/tag"

import backIcon from "../../../img/back-icon.svg"
import domoMembers from "../../../img/domo/domo-members.svg"
import domoStudentsPercentile from "../../../img/domo/domo-students-percentile.png"
import domoBusinessModelCanvas from "../../../img/domo/domo-business-model-canvas.svg"
import domoRef from "../../../img/domo/domo-ref.svg"
import domoCoverImg from "../../../img/domo/domo-cover.svg"
import domoEmotionalPlusTechImg from "../../../img/domo/emotional-tech-image.svg"
import domoPersonaImg from "../../../img/domo/domo-persona-image.svg"
import domoServiceBlueprintImg from "../../../img/domo/service-blueprint-image.svg"
import domoAppLatestImg from "../../../img/domo/domo-app-latest.svg"
import domoAppExampleImg from "../../../img/domo/domo-app-example-image.svg"
import domoLineImg from "../../../img/domo/domo-line-image.svg"
import domoAwardedImg from "../../../img/domo/domo-awarded-img.png"

const ContentWrapper = styled.div`
  width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  margin-bottom: -40px;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 140px);
    padding: 0 70px;
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 52px);
    padding: 0 27px;
  }
`

const LineWrapperImage = styled.img`
  width: 1px;
  height: 350px;
  object-fit: cover;
  ${(props) => props.mt && `margin-top: ${props.mt}px`};

  @media screen and (max-width: 768px) {
    height: 200px;
  }
`

const DomoApp = () => {
  const navigate = useNavigate()

  return (
    <>
      <BackIconWrapper onClick={() => navigate(-1)}>
        <img src={backIcon} width={8} height={15} alt="Back Icon" />
        <ContentTextNormal>Projects</ContentTextNormal>
      </BackIconWrapper>
      <ProjectDetailContainerStyled>
        <ContentWrapper>
          <ProjectTextTitleV2 SourceCodePro fontSize="40">
            2022
          </ProjectTextTitleV2>
          <ProjectSubTitleV2>Domo: Travel to home from home</ProjectSubTitleV2>
          <OutsideImageWrapperLink
            target="_blank"
            href="https://www.themedattraction.com/2022-student-showcase-results/">
            <ProjectImageWrapper mt="20" src={domoAwardedImg} alt="Domo Awarded Image" />
          </OutsideImageWrapperLink>
          <ContentTextNormal>
            This project was formed from a diverse team member, we were all sharing the same problems, situation, and
            were interested in the new technology, Metaverse. We wanted to create something fun and futuristic that we
            have never done it before in any class. So, we ended up building Domo, a cross-platform that allows you to
            ‘travel to and from home.’
          </ContentTextNormal>
          <ContentTextBold mt="-10">
            Project Contributors: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>Enya Huang, Flavia Yoshitake, Lina Edris, and Jevin Chen</ContentTextNormal>
          </ContentTextBold>
          <ContentTextBold mt="-10">
            My Role: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>
              My main responsibilities are the experience, branding, user interfaces, and graphic related elements
            </ContentTextNormal>
          </ContentTextBold>
          <TagWrapper mt="-10">
            <Tag>user interface design</Tag>
            <Tag>user experience design</Tag>
            <Tag>research</Tag>
            <Tag>branding</Tag>
            <Tag>metaverse</Tag>
            <Tag>SME</Tag>
            <Tag>app design</Tag>
            <Tag>service design</Tag>
            <Tag>figma</Tag>
          </TagWrapper>

          <OutsideImageWrapper>
            <ProjectImageWrapper mt="20" src={domoCoverImg} alt="Domo App Poster" />
          </OutsideImageWrapper>

          <SectionTitleV2 mt="20">Curious about how did we get here? Keep scrolling for details.</SectionTitleV2>
          <SectionTitleV2 mt="-30">
            If not, you can absolutely{" "}
            <ClickableSectionTitleV2 href="#outcome">jump to the outcome.</ClickableSectionTitleV2>
          </SectionTitleV2>
          <ContentTextNormal mt="-10">
            As we are all international students, with various interests, and traditions. We noticed despite our
            differences, we are pursuing our dreams at SCAD Savannah, yet missing our homes. And we are not alone...
          </ContentTextNormal>

          <OutsideImageWrapper mt="20">
            <ProjectImageWrapper src={domoStudentsPercentile} alt="Domo Students Percentile" />
          </OutsideImageWrapper>

          <SectionTitleV2 mt="20">
            How might we establish an emotional connection to help us feel at home even with the physical distance?
          </SectionTitleV2>
          <ContentTextNormal mt="-10">
            We use technology to form an emotional connection. Through travel-porta-ting our users, we aim to get them
            to feel more connected to their loved ones and their culture.
          </ContentTextNormal>

          <OutsideImageWrapper mt="20">
            <ProjectImageWrapper src={domoEmotionalPlusTechImg} alt="Domo Emotional and Technology" />
          </OutsideImageWrapper>

          <SectionTitleV2 mt="20">Who is our target?</SectionTitleV2>
          <ContentTextNormal mt="-10">
            Meet Anna, an international student from Shanghai. She is one of the 1 million international students
            studying in the U.S. Anna has been having a great US experience. However, it’s the first year to be away
            from family during the celebration of the Lunar Festival.
          </ContentTextNormal>

          <OutsideImageWrapper mt="20">
            <ProjectImageWrapper src={domoPersonaImg} alt="Domo Persona" />
          </OutsideImageWrapper>

          <SectionTitleV2 mt="20">Ok, we have our target. But how is this gonna work?</SectionTitleV2>
          <ContentTextNormal mt="-10">This service blueprint shows:</ContentTextNormal>
          <BulletWrapper mt="-20" listStyleType="decimal">
            <li>
              user journey of a user when using Domo, either on their phone, or through Metaverse (using VR headset).
              The VR headset is not required, but recommended for a better experience.
            </li>
            <li>User journey of a merchant, registering and selling food on Domo.</li>
            <li>Domo services - how we provide service and experience to user.</li>
          </BulletWrapper>

          <OutsideImageWrapper mt="0">
            <ProjectImageWrapper src={domoServiceBlueprintImg} alt="Domo Service Blueprint" />
          </OutsideImageWrapper>

          <SectionTitleV2 mt="20" id="outcome">
            Here’s Domo :)
          </SectionTitleV2>
          <ContentTextNormal mt="-10">
            Domo AKA ‘house’ in Esperanto. An immersive marketplace that offers a curated selection of cultural
            activities and high-quality products in a hybrid environment. It means that you can enjoy a digital and
            physical experience without leaving your home. While offering an authentic cultural and social experience to
            final consumers, Domo also provides an alternative sales channel for Small and Mid-size Enterprises (SME) to
            sell on metaverse.
          </ContentTextNormal>
          <ContentTextNormal mt="-10">
            You can enjoy Domo experience from your phone, or get a better experience with VR headset, totally up to
            you!
          </ContentTextNormal>

          <ProjectImageWrapper mt="20" src={domoAppLatestImg} alt="Domo App Latest" />
          <OutsideImageWrapper mt="20">
            <ProjectImageWrapper src={domoAppExampleImg} alt="Domo App Example" />
          </OutsideImageWrapper>

          <OutsideImageWrapper mt="20">
            <ProjectImageWrapper src={domoBusinessModelCanvas} alt="Domo App Business Model Canvas" />
          </OutsideImageWrapper>

          <SectionTitleV2 mt="20">Thank you and hope you enjoy this project!</SectionTitleV2>
          <SectionTitleV2 mt="-30">
            {" "}
            Of course, you can keep browsing{" "}
            <ClickableSectionTitleV2 onClick={() => navigate("/projects")}>more projects</ClickableSectionTitleV2> ;)
          </SectionTitleV2>

          <LineWrapperImage mt="20" width="1px" height="350" src={domoLineImg} alt="Domo Line" />

          <SectionTitleV2 mt="20">Appendix</SectionTitleV2>

          <OutsideImageWrapper mt="0">
            <ProjectImageWrapper mt="20" src={domoMembers} alt="Domo App Members" />
          </OutsideImageWrapper>

          <OutsideImageWrapper mt="-35">
            <ProjectImageWrapper src={domoRef} alt="Domo App Reference" />
          </OutsideImageWrapper>
        </ContentWrapper>
      </ProjectDetailContainerStyled>
    </>
  )
}

export default DomoApp
