import React from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import {
  ContentTextNormal,
  BackIconWrapper,
  ProjectTextTitle,
  ProjectTextTitleV2,
  ProjectSubTitleV2,
  ContentTextBold,
  ProjectImageWrapper,
  ProjectDetailContainerStyled,
  Gap,
  ContentTextNormalWithBold,
  Input,
  BulletWrapper,
  SectionTitleV2,
  OutsideImageWrapper,
} from "../../../components/shared/shared"
import { TagWrapper, Tag } from "../../../components/tag/tag"
import { Button } from "../../../components/button/button"
import { ModalWrapper, ModalContent, ModalCloseButton } from "../../../components/modal/modal"

import backIcon from "../../../img/back-icon.svg"
import rspUserAmplitudeImg from "../../../img/rsp-personas/user-in-system.svg"
import rspBrowserUsageImg from "../../../img/rsp-personas/browser-usage.svg"
import rspStateUsageImg from "../../../img/rsp-personas/state-usage.svg"
import rspReportStatisticImg from "../../../img/rsp-personas/report-statistic.svg"
import rspPersonaImg from "../../../img/rsp-personas/rsp-persona.svg"
import rspUserTypesStatisticImg from "../../../img/rsp-personas/user-types-statistic.svg"
import rspPersonaExampleImg from "../../../img/rsp-personas/rsp-persona-example.svg"
import rspAllOfPersonasImg from "../../../img/rsp-personas/all-of-personas.svg"
import rspPersonaProcessImg from "../../../img/rsp-personas/summary-of-persona-process.svg"
import rspInsightsOutliersImg from "../../../img/rsp-personas/insights-outliers-img.svg"

import { PASSCODE } from "../rsp-insurance/constants"

const ContentWrapper = styled.div`
  width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 140px);
    padding: 0 70px;
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 52px);
    padding: 0 27px;
  }
`

const RentSpreePersonas = () => {
  const navigate = useNavigate()
  const [userPasscode, setUserPasscode] = React.useState(null)
  const [isPasscodeCorrect, setIsPasscodeCorrect] = React.useState(false)
  const [passcodeIncorrect, setPasscodeIncorrect] = React.useState(false)
  const [passcodeErrorMsg, setPasscodeErrorMsg] = React.useState(false)

  const validatePassword = () => {
    if (userPasscode === PASSCODE) {
      setIsPasscodeCorrect(true)
    } else {
      setPasscodeIncorrect(true)
      if (!userPasscode) setPasscodeErrorMsg("Required.")
      else setPasscodeErrorMsg("That's not the correct passcode, Please try again.")
    }
  }

  if (!isPasscodeCorrect)
    return (
      <ModalWrapper>
        <ModalContent>
          <ModalCloseButton onClick={() => navigate(-1)}>&times;</ModalCloseButton>
          <ProjectTextTitle mt="0">Password Required</ProjectTextTitle>
          <ContentTextNormal>
            The password is required to view this project due to the{" "}
            <ContentTextNormalWithBold>Non-discloser Agreement.</ContentTextNormalWithBold> Please enter the password or
            contact <a href="mailto: pirpiep@gmail.com">pirpiep@gmail.com</a> for inquiries.
          </ContentTextNormal>
          <Gap height="10" />
          <ContentTextNormal>
            Password <span style={{ color: "#F15555", fontSize: "16px", fontWeight: "normal" }}>*</span>
          </ContentTextNormal>
          <Gap height="5" />
          <Input
            type="password"
            name="passcode"
            id="passcode"
            onChange={({ target: { value } }) => setUserPasscode(value)}
          />
          <Gap height="5" />
          {passcodeIncorrect && <ContentTextNormalWithBold color="red">{passcodeErrorMsg}</ContentTextNormalWithBold>}
          <Gap height="20" />
          <Button Lato onClick={validatePassword}>
            Enter
          </Button>
        </ModalContent>
      </ModalWrapper>
    )

  return (
    <>
      <BackIconWrapper onClick={() => navigate(-1)}>
        <img src={backIcon} width={8} height={15} alt="Back Icon" />
        <ContentTextNormal>Projects</ContentTextNormal>
      </BackIconWrapper>
      <ProjectDetailContainerStyled>
        <ContentWrapper>
          <ProjectTextTitleV2 SourceCodePro fontSize="40">
            2020
          </ProjectTextTitleV2>
          <ProjectSubTitleV2>RentSpree: Persona Project</ProjectSubTitleV2>
          <ContentTextNormal>
            In the past few years, Rentspree has been in the real estate market and is known as one of the best
            screening platforms in the States. We try very hard to stay on top of the market, keep up with the
            competitors, and deliver the best product to our users. We have many features and projects lined up in the
            roadmap that we think will benefit users. However, we have never actually known our users. Sad but true, we
            need to know who they are and study their behavior in order to deliver the best product for them.
          </ContentTextNormal>
          <ContentTextBold mt="-10">
            Objective: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>
              We create this persona project to understand more about our users in the RentSpree platform, and grouping
              our users for a clearer picture, and understand most thoroughly in terms of insight, need, pain, behavior,
              and attitude
            </ContentTextNormal>
          </ContentTextBold>
          <ContentTextBold mt="-10">Goals:</ContentTextBold>
          <BulletWrapper mt="-20">
            <li>Designers will be able to understand the feature serve which persona</li>
            <li>Designers will be able to create each feature under the persona pain point</li>
            <li>Marketers will be able to bring personas to create marketing content for a specific target audience</li>
            <li>Researchers will be able to interview the right target audience</li>
            <li>Teams will understand the insight overview of each persona to make teams focus on the same page.</li>
          </BulletWrapper>
          <ContentTextBold mt="-20">
            Project Contributors: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>RentSpree Design team (BKK)</ContentTextNormal>
          </ContentTextBold>
          <ContentTextNormal mt="-10" color="#8A0000">
            **This project contains sensitive data. It cannot be shared to anywhere and can only be viewed with
            permission on www.pirpie.com.**
          </ContentTextNormal>
          <TagWrapper>
            <Tag>user research</Tag>
            <Tag>persona</Tag>
            <Tag>analysis</Tag>
            <Tag>amplitude</Tag>
            <Tag>desk research</Tag>
          </TagWrapper>

          <ContentTextBold>Summary of my process:</ContentTextBold>
          <ProjectImageWrapper src={rspPersonaProcessImg} alt="RSP Persona Process" />
          <ContentTextNormal>We used the information from 3 sources to create personas:</ContentTextNormal>
          <BulletWrapper mt="-18" style={{ listStyleType: "decimal" }}>
            <li>Amplitude data (See the differences of users' behavior in our platform between groups)</li>
            <li>Social profile data (Search the name of the user and grab the important insight information)</li>
            <li>
              Interview (Get information directly from the user and gather important
              need/problem/goal/behavior/attitudes)
            </li>
          </BulletWrapper>

          <SectionTitleV2>Understanding user</SectionTitleV2>
          <ContentTextNormal mt="-10">
            With a little help of our data team, we got the total user in our system.
          </ContentTextNormal>
          <ProjectImageWrapper mt="20" src={rspUserAmplitudeImg} alt="RSP User In System Image" />
          <SectionTitleV2 mt="20">The 81% is not our target user. But the 60,119+ people are ;)</SectionTitleV2>
          <ContentTextNormal mt="-10">
            Yes, we will be focusing on Landlords, Agents, and Property Management. (For now.)
          </ContentTextNormal>
          <SectionTitleV2 mt="20">Go back to ask ourselves, what do we do?</SectionTitleV2>
          <ContentTextNormal mt="-10">
            RentSpree, Inc. is an online rental application and tenant screening platform, we make profit from the
            credit score screening fee. So, we ran data analysis on Amplitude to see the user who create most profit to
            us, in this case, it’s use who get the most report from their tenant.
          </ContentTextNormal>
          <ContentTextNormal mt="-10">
            From data, we got 30 users who get more than 100 screening reports, and we will narrow down to these people.
          </ContentTextNormal>
          <ProjectImageWrapper mt="20" src={rspReportStatisticImg} alt="RSP Report Statistic Image" />
          <SectionTitleV2 mt="20">Forming Personas</SectionTitleV2>
          <ContentTextNormal mt="-10">
            We categorized user by their profession into 4 groups: Agent, Broker, Property Manager, and Landlord. After
            that we ran additional data analysis on our top users to see their behavior and pattern such as device used,
            time spent, feature used, etc.
          </ContentTextNormal>
          <ProjectImageWrapper src={rspBrowserUsageImg} alt="RSP Browser Usage Image" />
          <ProjectImageWrapper src={rspStateUsageImg} alt="RSP State Usage Image" />

          <SectionTitleV2 mt="20">Insights and Outliers</SectionTitleV2>
          <ContentTextNormal mt="-10">
            We got quiet interesting behavior on each account! Here’s the example of what we found
          </ContentTextNormal>
          <ProjectImageWrapper mt="20" src={rspInsightsOutliersImg} alt="RSP Persona Insight and Outliers" />
          <ContentTextNormal mt="20">
            We make assumption for each insights/fact, and we went online to find prove. As most real estate agents have
            their public profile, so we searched their social appearance to see if we were able to get their work
            experience, company profile, and other interesting insights.
          </ContentTextNormal>
          <ContentTextNormal mt="-10">
            Apart from that, we have 50 interviews conducted by the US team in 2019, we watched the recorded interviews
            again and tried to take as many information and insights as possible.
          </ContentTextNormal>
          <ContentTextNormal mt="-10">Finally, as of December 2020, we have our 11 personas.</ContentTextNormal>
          <ContentTextNormal mt="-10">
            11 personas sound like a lot, yes, but these are the best we have built! Understanding persona will make us
            understand user and able to create and deliver the best expereince for them.{" "}
          </ContentTextNormal>

          <OutsideImageWrapper>
            <ProjectImageWrapper mt="20" src={rspPersonaImg} alt="RSP Persona Image" />
          </OutsideImageWrapper>

          <OutsideImageWrapper>
            <ProjectImageWrapper src={rspUserTypesStatisticImg} alt="RSP User Types Statistic Image" />
          </OutsideImageWrapper>

          <ContentTextBold mt="20">Example of RentSpree’s persona</ContentTextBold>
          <ProjectImageWrapper src={rspPersonaExampleImg} alt="RSP Personas Example" />

          <SectionTitleV2 mt="20">#remember_me</SectionTitleV2>
          <ContentTextNormal mt="-10">
            To make our employees remember and understand our personas better, product designer Poom Pirothpongpun and
            I, have created a persona boardgame for internal member to play and get familiar with our 11 personas more!
          </ContentTextNormal>
          <ProjectImageWrapper src={rspAllOfPersonasImg} alt="RSP All of Personas" />
        </ContentWrapper>
      </ProjectDetailContainerStyled>
    </>
  )
}

export default RentSpreePersonas
