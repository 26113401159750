import React from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import {
  ContentTextNormal,
  BackIconWrapper,
  ProjectTextTitleV2,
  ProjectSubTitleV2,
  ContentTextBold,
  ProjectImageWrapper,
  ProjectDetailContainerStyled,
  FullImageWrapper,
  Gap,
  BulletWrapper,
  ContentTextNormalLink,
} from "../../../components/shared/shared"
import { TagWrapper, Tag } from "../../../components/tag/tag"

import backIcon from "../../../img/back-icon.svg"
// import starbucksBluePrintImg from "../../../img/startbucks/starbucks-blueprint.png"
import starbucksJourneyImg from "../../../img/startbucks/startbucks-full-details.png"

const ContentWrapper = styled.div`
  width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 140px);
    padding: 0 70px;
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 52px);
    padding: 0 27px;
  }
`

const ServiceDesignExperiment = () => {
  const navigate = useNavigate()

  return (
    <>
      <BackIconWrapper onClick={() => navigate(-1)}>
        <img src={backIcon} width={8} height={15} alt="Back Icon" />
        <ContentTextNormal>Projects</ContentTextNormal>
      </BackIconWrapper>
      <ProjectDetailContainerStyled>
        <ContentWrapper>
          <ProjectTextTitleV2 SourceCodePro fontSize="40">
            2020
          </ProjectTextTitleV2>
          <ProjectSubTitleV2 mt="-20">Service Design Experiment: What happens during a service</ProjectSubTitleV2>
          <ContentTextNormal>
            The goal of this experiment is to identify the journey of the Starbucks during the Covid-19 crisis. What’s
            exciting about this subject is that it has three different journey maps.
          </ContentTextNormal>
          <ContentTextNormal>
            The purpose of this experiment based on the current situation; Why we should wear masks and wash hands
            frequently, What Starbucks can be improved in the future , aand Where is the potential risks of the journey.
          </ContentTextNormal>
          <ContentTextBold>
            Project Contributors: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>Yongqifang Hu, Jun Zhao, and Jiajin Lan</ContentTextNormal>
          </ContentTextBold>
          <ContentTextBold>
            Objective: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>To create a customer journey map and service blueprint.</ContentTextNormal>
          </ContentTextBold>
          <ContentTextBold>
            Process: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>
              We figured out the differences between the 3 ways of services: drive-thru, online pick-up and walk-in.
              This storyboard allows the reader to understand the customer’s actions. We highlighted the potential risk
              of the whole process. To make our audiences understand this map clearly, we put the keys in the top right
              corner of the infographic.{" "}
            </ContentTextNormal>
          </ContentTextBold>
          <ContentTextBold>
            Featured in: &nbsp;&nbsp;&nbsp;
            <BulletWrapper mt="10">
              <li>
                <ContentTextNormalLink
                  target="_blank"
                  href="https://www.eleken.co/blog-posts/customer-journey-map-examples-to-get-some-fresh-ideas">
                  https://www.eleken.co/
                </ContentTextNormalLink>
              </li>
              <li>
                <ContentTextNormalLink
                  target="_blank"
                  href="https://jm.goodux.cn/file/Customer%20Journey%20Maps%20%E2%80%93%20144%20Examples%20&%20Templates,%20From%20User%20Interviews.pdf">
                  https://jm.goodux.cn/
                </ContentTextNormalLink>
              </li>
              <li>
                <ContentTextNormalLink target="_blank" href="https://pioneer-2021.tistory.com/17">
                  https://pioneer-2021.tistory.com/17/
                </ContentTextNormalLink>
              </li>
            </BulletWrapper>
          </ContentTextBold>
          <TagWrapper>
            <Tag>service design</Tag>
            <Tag>graphic design</Tag>
            <Tag>adobe illustrator</Tag>
            <Tag>illustration</Tag>
            <Tag>procreate</Tag>
            <Tag>user journey</Tag>
          </TagWrapper>
          <Gap height="20" />
        </ContentWrapper>
        <FullImageWrapper>
          <ProjectImageWrapper src={starbucksJourneyImg} alt="Pie Starbucks Journey" />
        </FullImageWrapper>
      </ProjectDetailContainerStyled>
    </>
  )
}

export default ServiceDesignExperiment
