import React from "react"
import styled from "styled-components"

import { useNavigate } from "react-router-dom"

import { ContainerStyled, BackgroundWrapper, ContentTextNormal } from "../../components/shared/shared"

import homeBg from "../../img/home-bg.png"
import serviceExplorationSavannahRiverboatCruise from "../../img/service-exploration/riverboat-img.png"
import throughOurEyesImg from "../../img/through-our-eyes/toe-pic-01.png"
import serviceDesignExperiment from "../../img/all-projects/starbucks-cover-image.png"
import serviceDesignExperimentHover from "../../img/all-projects/starbucks-hover-image.png"
import rspRentalIndustryCoverImage from "../../img/all-projects/rsp-rental-industry-cover-image.png"
import rspRentalIndustryHoverImage from "../../img/all-projects/rsp-rental-industry-hover-image.png"
import hybridgeCoverImagePng from "../../img/all-projects/hybridge-cover-image.png"
import hybridgeCoverImageGif from "../../img/all-projects/hybridge-cover-image.gif"
import jordiDesignForOnePngImage from "../../img/all-projects/jordi-cover-image.png"
import jordiDesignForOneGifImage from "../../img/all-projects/jordi-cover-image.gif"
import plantRiversideDistrictImagePng from "../../img/all-projects/plant-cover-image.png"
import plantRiversideDistrictImageGif from "../../img/all-projects/plant-cover-image.gif"
import rspRenterInsuranceImagePng from "../../img/all-projects/renter-insurance-cover-image.png"
import rspRenterInsuranceHoverImagePng from "../../img/all-projects/renter-insurance-hover-image.png"
import rspRenterInsuranceResearchImagePng from "../../img/all-projects/renter-insurance-research-cover-image.png"
import rspRenterInsuranceResearchHoverImagePng from "../../img/all-projects/renter-insurance-research-hover-image.png"
import rspPersonasImage from "../../img/all-projects/rentspree-personas-cover-image.png"
import rspPersonasHoverImage from "../../img/all-projects/rentspree-personas-hover-image.png"
import keyIcon from "../../img/key-icon.svg"
import throughOurEyesGif from "../../img/all-projects/toe.gif"
import RiverboatGif from "../../img/all-projects/river-boast.gif"
import athenaImagePng from "../../img/all-projects/athena-cover-image.png"
import athenaImageGif from "../../img/all-projects/athena-cover-image.gif"
import domoImagePng from "../../img/all-projects/domo-cover-image.png"
import domoImageGif from "../../img/all-projects/domo-cover-image.gif"
import coFarmCoverImage from "../../img/all-projects/cofarm-cover-image.png"

const ProjectContainer = styled.div`
  width: 1280px;
  display: flex;
  // TODO: Enable this style when rentspree insurance is ready
  /* justify-content: space-around; */
  flex-wrap: wrap;
  padding: 0 60px;
  margin-top: 45px;
  gap: 35px;
  margin-bottom: 40px;

  // TODO: Remove this style when rentspree insurance is ready
  @media screen and (max-width: 1024px) {
    justify-content: center;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    justify-content: center;
  }

  @media screen and (max-width: 414px) {
    margin-top: 20px;
    padding: 0 20px;
    gap: 15px;
  }
`

const ProjectImageWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  @media screen and (max-width: 991px) {
    // TODO: Adjust mobile image size
    align-items: center;
    width: 100%;
  }
`

const ProjectImage = styled.img`
  width: ${(props) => (props.width ? `${props.width}px;` : "400px")};
  height: ${(props) => (props.height ? `${props.height}px;` : "250px")};
  object-fit: cover;

  @media screen and (max-width: 991px) {
    width: 100%;
    padding: 0 20px;
    height: auto;
  }

  background: transparent;
`

const ProfileTitile = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #333;

  @media screen and (max-width: 991px) {
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    font-size: 14px;
  }
`

const IconImageWrapper = styled.img`
  font-size: 16px;
  margin-right: 5px;
  width: 16px;
  height: 16px;
`

const Project = () => {
  const navigate = useNavigate()

  const handleHoverProjects = (name, img) => {
    const staticImage = document.getElementById(name)
    staticImage.src = img
  }

  const handleBackgroundOverScroll = () => {
    const scroll = window.scrollY
    const bg = document.getElementById("project-bg")
    bg.style.height = `calc(100% + ${scroll}px)`
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleBackgroundOverScroll)
    return () => {
      window.removeEventListener("scroll", handleBackgroundOverScroll)
    }
  }, [])

  return (
    <ContainerStyled>
      <BackgroundWrapper id="project-bg" img={homeBg} />
      <ProjectContainer>
        <ProjectImageWrapper onClick={() => navigate("/project/cofarm")}>
          <ProjectImage id="co-farm" src={coFarmCoverImage} alt="Pie's Co Farm App Thesis" />
          <ProfileTitile>CoFarm: Agritourism Application Design</ProfileTitile>
          <ContentTextNormal mt="-5" color="#00818A" fontSize="14">
            Design research, Service design, Product design
          </ContentTextNormal>
        </ProjectImageWrapper>

        <ProjectImageWrapper
          onClick={() => navigate("/project/domo")}
          onMouseEnter={() => handleHoverProjects("domo", domoImageGif)}
          onMouseLeave={() => handleHoverProjects("domo", domoImagePng)}>
          <ProjectImage id="domo" src={domoImagePng} alt="Pie's Domo App Cover Image" />
          <ProfileTitile>Domo: Travel to and from home</ProfileTitile>
          <ContentTextNormal mt="-5" color="#00818A" fontSize="14">
            Design research, Product design, Branding, UX/UI
          </ContentTextNormal>
        </ProjectImageWrapper>

        <ProjectImageWrapper
          onClick={() => navigate("/project/hybridge")}
          onMouseEnter={() => handleHoverProjects("hybridge", hybridgeCoverImageGif)}
          onMouseLeave={() => handleHoverProjects("hybridge", hybridgeCoverImagePng)}>
          <ProjectImage
            id="hybridge"
            src={hybridgeCoverImagePng}
            alt="Pie's Hybridge Connecting Future Professionals"
          />
          <ProfileTitile>Hybridge: Connecting the future’s professionals</ProfileTitile>
          <ContentTextNormal mt="-5" color="#00818A" fontSize="14">
            Design research, Product design, Branding, UX/UI
          </ContentTextNormal>
        </ProjectImageWrapper>

        <ProjectImageWrapper
          onClick={() => navigate("/project/rsp-renter-insurance")}
          onMouseEnter={() => handleHoverProjects("renter-insurance", rspRenterInsuranceHoverImagePng)}
          onMouseLeave={() => handleHoverProjects("renter-insurance", rspRenterInsuranceImagePng)}>
          <ProjectImage
            id="renter-insurance"
            src={rspRenterInsuranceImagePng}
            alt="RentSpree Renter Insurance Cover Image"
          />
          <ProfileTitile>
            <IconImageWrapper src={keyIcon} alt="Key Icon" />
            RentSpree: Renters Insurance 1.0
          </ProfileTitile>
          <ContentTextNormal mt="-5" color="#00818A" fontSize="14">
            Product design, UX/UI design, Prototyping, Testing
          </ContentTextNormal>
        </ProjectImageWrapper>

        <ProjectImageWrapper
          onClick={() => navigate("/project/rsp-insurance-research")}
          onMouseEnter={() => handleHoverProjects("renter-insurance-research", rspRenterInsuranceResearchHoverImagePng)}
          onMouseLeave={() => handleHoverProjects("renter-insurance-research", rspRenterInsuranceResearchImagePng)}>
          <ProjectImage
            id="renter-insurance-research"
            src={rspRenterInsuranceResearchImagePng}
            alt="RentSpree Renter Insurance Research Cover Image"
          />
          <ProfileTitile>
            <IconImageWrapper src={keyIcon} alt="Key Icon" />
            RentSpree: Renters Insurance improvements
          </ProfileTitile>
          <ContentTextNormal mt="-5" color="#00818A" fontSize="14">
            User research, Product design, UX/UI design
          </ContentTextNormal>
        </ProjectImageWrapper>

        <ProjectImageWrapper
          onClick={() => navigate("/project/rsp-personas")}
          onMouseEnter={() => handleHoverProjects("rsp-persona", rspPersonasHoverImage)}
          onMouseLeave={() => handleHoverProjects("rsp-persona", rspPersonasImage)}>
          <ProjectImage id="rsp-persona" src={rspPersonasImage} alt="RentSpree Personas Cover Image" />
          <ProfileTitile>
            <IconImageWrapper src={keyIcon} alt="Key Icon" />
            RentSpree: Persona Project
          </ProfileTitile>
          <ContentTextNormal mt="-5" color="#00818A" fontSize="14">
            User research, Data analysis
          </ContentTextNormal>
        </ProjectImageWrapper>

        <ProjectImageWrapper
          onClick={() => navigate("/project/rsp-rental-industry")}
          onMouseEnter={() => handleHoverProjects("rsp-rental-industry", rspRentalIndustryHoverImage)}
          onMouseLeave={() => handleHoverProjects("rsp-rental-industry", rspRentalIndustryCoverImage)}>
          <ProjectImage
            id="rsp-rental-industry"
            src={rspRentalIndustryCoverImage}
            alt="RentSpree Rental Industry Cover Image"
          />
          <ProfileTitile>RentSpree: Rental Industry Infographics</ProfileTitile>
          <ContentTextNormal mt="-5" color="#00818A" fontSize="14">
            Visualization, Infographics, Graphic design
          </ContentTextNormal>
        </ProjectImageWrapper>

        <ProjectImageWrapper
          onClick={() => navigate("/project/athena")}
          onMouseEnter={() => handleHoverProjects("athena", athenaImageGif)}
          onMouseLeave={() => handleHoverProjects("athena", athenaImagePng)}>
          <ProjectImage id="athena" src={athenaImagePng} alt="Pie's Athena Cover Image" />
          <ProfileTitile>Athena - Service Design Project</ProfileTitile>
          <ContentTextNormal mt="-5" color="#00818A" fontSize="14">
            Design research, Service design, Product design
          </ContentTextNormal>
        </ProjectImageWrapper>

        <ProjectImageWrapper
          onClick={() => navigate("/project/toe")}
          onMouseEnter={() => handleHoverProjects("toe", throughOurEyesGif)}
          onMouseLeave={() => handleHoverProjects("toe", throughOurEyesImg)}>
          <ProjectImage id="toe" src={throughOurEyesImg} alt="Pie's Through Our Eyes" />
          <ProfileTitile>Service Design Book: Through Our Eyes</ProfileTitile>
          <ContentTextNormal mt="-5" color="#00818A" fontSize="14">
            Visualization, Book design, Graphic design
          </ContentTextNormal>
        </ProjectImageWrapper>

        <ProjectImageWrapper
          onClick={() => navigate("/project/plant-riverside")}
          onMouseEnter={() => handleHoverProjects("plant", plantRiversideDistrictImageGif)}
          onMouseLeave={() => handleHoverProjects("plant", plantRiversideDistrictImagePng)}>
          <ProjectImage id="plant" src={plantRiversideDistrictImagePng} alt="Pie's Plant Riverside District Image" />
          <ProfileTitile>Plant Riverside District</ProfileTitile>
          <ContentTextNormal mt="-5" color="#00818A" fontSize="14">
            Design research
          </ContentTextNormal>
        </ProjectImageWrapper>

        {/* <ProjectImageWrapper
          onClick={() => navigate("/project/jordi")}
          onMouseEnter={() => handleHoverProjects("jodi", jordiDesignForOneGifImage)}
          onMouseLeave={() => handleHoverProjects("jodi", jordiDesignForOnePngImage)}>
          <ProjectImage id="jodi" src={jordiDesignForOnePngImage} alt="Pie's Jordi Design For One" />
          <ProfileTitile>Jordi: Design for one</ProfileTitile>
          <ContentTextNormal mt="-5" color="#00818A" fontSize="14">
            Design research, Product design, Branding
          </ContentTextNormal>
        </ProjectImageWrapper> */}

        <ProjectImageWrapper
          onClick={() => navigate("/project/service-design-experiment")}
          onMouseEnter={() => handleHoverProjects("starbucks", serviceDesignExperimentHover)}
          onMouseLeave={() => handleHoverProjects("starbucks", serviceDesignExperiment)}>
          <ProjectImage id="starbucks" src={serviceDesignExperiment} alt="Pie's Service Design Experiment" />
          <ProfileTitile>Service Design Experiment</ProfileTitile>
          <ContentTextNormal mt="-5" color="#00818A" fontSize="14">
            Service design, Visualization, Graphic design
          </ContentTextNormal>
        </ProjectImageWrapper>

        <ProjectImageWrapper
          onClick={() => navigate("/project/exploration")}
          onMouseEnter={() => handleHoverProjects("service-exploration", RiverboatGif)}
          onMouseLeave={() => handleHoverProjects("service-exploration", serviceExplorationSavannahRiverboatCruise)}>
          <ProjectImage
            id="service-exploration"
            src={serviceExplorationSavannahRiverboatCruise}
            alt="Pie's Service Exploration Savannah Riverboat Cruise"
          />
          <ProfileTitile>Service Exploration: Savannah Riverboat Cruise</ProfileTitile>
          <ContentTextNormal mt="-5" color="#00818A" fontSize="14">
            Service design, Visualization, Graphic design
          </ContentTextNormal>
        </ProjectImageWrapper>
      </ProjectContainer>
    </ContainerStyled>
  )
}

export default Project
