import styled from "styled-components"

export const ContainerStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
`

export const BackgroundWrapper = styled.span`
  width: 100%;
  height: 100%;
  position: absolute;

  ${(props) => props.img && `background-image: url(${props.img})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  /* -webkit-background-position: center;
  -webkit-background-size: cover;
  -webkit-background-repeat: no-repeat;
  -webkit-background-attachment: fixed;

  -moz-background-position: center;
  -moz-background-size: cover;
  -moz-background-repeat: no-repeat;
  -moz-background-attachment: fixed;

  -o-background-position: center;
  -o-background-size: cover;
  -o-background-repeat: no-repeat;
  -o-background-attachment: fixed; */

  @media screen and (max-width: 991px) {
    display: none;
    /* -webkit-background-position: center;
    -webkit-background-size: cover;
    -webkit-background-repeat: no-repeat;
    -webkit-background-attachment: fixed;
    overflow: hidden; */

    /* -webkit-background-position: center; */
    /* -webkit-background-size: cover; */
    /* -webkit-background-repeat: no-repeat; */
    /* -webkit-background-attachment: fixed; */

    /* -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    -webkit-background-position: center;
    -moz-background-position: center;
    -o-background-position: center;
    background-position: center;

    -webkit-background-attachment: fixed;
    -moz-background-attachment: fixed;
    -o-background-attachment: fixed;
    background-attachment: fixed;

    -webkit-background-repeat: no-repeat;
    -moz-background-repeat: no-repeat;
    -o-background-repeat: no-repeat;
    background-repeat: no-repeat; */

    /* height: 100%; */
  }

  margin-top: -85px;
  z-index: -1; // For cliking any elements on screen over the background
`

export const Text18Normal = styled.span`
  font-weight: ${(props) => (props.weight ? `${props.weight};` : "normal")};
  color: ${(props) => (props.color ? props.color : "#000")};
  font-size: ${(props) => (props.size ? `${props.size}px;` : "18px")};
  line-height: 22px;
`

export const TitleNormal = styled.span`
  font-weight: normal;
  color: #333;
  font-size: 18px;
  letter-spacing: 0.05em;
  line-height: 22px;
  text-transform: uppercase;

  @media screen and (max-width: 414px) {
    ${(props) => props.mWidth && `width: ${props.mWidth}`};
    ${(props) => props.mCenter && "text-align: center"};
    font-size: 16px;
  }
`

export const TitleNormalLink = styled.a`
  font-weight: normal;
  color: #333;
  font-size: 14px;
  letter-spacing: 0.05em;
  line-height: 22px;
  text-transform: uppercase;

  @media screen and (max-width: 414px) {
    ${(props) => props.mWidth && `width: ${props.mWidth}`};
    ${(props) => props.mCenter && "text-align: center"};
    font-size: 14px;
  }

  ${(props) => props.mt && `margin-top: ${props.mt}px`};
`

export const ContentTextNormal = styled.span`
  ${(props) => props.mt && `margin-top: ${props.mt}px`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
  font-weight: 300;
  color: ${(props) => (props.color ? props.color : "#000")};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "16px")};
  line-height: 22px;
`

export const ContentTextNormalLink = styled.a`
  ${(props) => props.mt && `margin-top: ${props.mt}px`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
  font-weight: 300;
  color: ${(props) => (props.color ? props.color : "#000")};
  font-size: 16px;
  line-height: 22px;

  ${(props) => props.isDisabledUnderline && "text-decoration: none"};
`

export const ContentTextNormalWithBold = styled.span`
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.mt && `margin-top: ${props.mt}px`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  color: ${(props) => (props.color ? props.color : "#000")};
  font-size: 16px;
  line-height: 22px;

  @media screen and (max-width: 414px) {
    ${(props) => props.mWidth && `width: ${props.mWidth}`};
  }
`

export const ContentTextBold = styled.span`
  ${(props) => props.width && `width: ${props.width}`};
  font-weight: ${(props) => (props.weight ? props.weight : "bold")};
  ${(props) => props.mt && `margin-top: ${props.mt}px`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}px`};
  color: ${(props) => (props.color ? props.color : "#000")};
  font-size: 16px;
  line-height: 22px;

  @media screen and (max-width: 414px) {
    ${(props) => props.mWidth && `width: ${props.mWidth}`};
  }
`

export const BackIconWrapper = styled.div`
  width: calc(100% - 65px);
  height: 20px;
  display: flex;
  justify-content: row;
  align-items: center;
  margin-top: 15px;
  padding-left: 65px;
  gap: 10px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: calc(100% - 27px);
    padding-left: 27px;
  }
`

export const ProjectTextTitle = styled.div`
  color: #00818a;
  font-weight: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 29px;
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : "30px")};
`

export const ProjectTextTitleV2 = styled.div`
  font-family: Source Code Pro, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  color: #00818a;
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : "30px")};

  @media screen and (max-width: 414px) {
    font-size: 36px;
  }
`

export const ProjectSubTitleV2 = styled.div`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #000000;
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : "-30px")};

  @media screen and (max-width: 414px) {
    font-size: 24px;
  }
`

export const ProjectDetailTitleV2 = styled.div`
  font-family: ${(props) => (props.Lato ? "Lato, sans-serif;" : "Source Code Pro, sans-serif")};
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
  color: ${(props) => (props.color ? props.color : "#fff")};

  @media screen and (max-width: 414px) {
    font-size: 20px;
  }
`

export const ProjectDetailTitleShadowV2 = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
`

export const ProjectBackgroundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  ${(props) => props.height && `height: ${props.height}`};

  ${(props) => props.img && `background-image: url(${props.img})`};
  background-repeat: no-repeat;

  padding: 5% 14.3%;
  margin-left: -14.3%;

  margin-top: ${(props) => (props.mt ? `${props.mt}px` : "0px")};

  @media screen and (max-width: 768px) {
    width: calc(100% - 40px);
    ${(props) => props.height && "height: auto"};
    ${(props) => props.mBgColor && "background-image: none"};
    background-color: ${(props) => (props.mBgColor ? `${props.mBgColor}` : "#1b7bd4")};
    margin-left: 0px;
    padding: 20px;
  }
`

export const OutsideImageWrapper = styled.div`
  width: ${(props) => (props.width ? props.width : "128.6%")};
  margin-left: -14.3%;

  ${(props) => props.mt && `margin-top: ${props.mt}px`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}px`};
  object-fit: cover;
  ${(props) => props.center && "align-self: center"};

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
  }
`

export const OutsideImageWrapperLink = styled.a`
  width: ${(props) => (props.width ? props.width : "128.6%")};
  margin-left: -14.3%;

  ${(props) => props.mt && `margin-top: ${props.mt}px`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}px`};
  object-fit: cover;
  ${(props) => props.center && "align-self: center"};

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
  }
`

export const ProjectImageWrapper = styled.img`
  ${(props) => props.mt && `margin-top: ${props.mt}px`};
  ${(props) => props.mtPercent && `margin-top: ${props.mtPercent}`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}px`};
  width: ${(props) => (props.width ? props.width : "100%")};
  object-fit: cover;
  ${(props) => props.center && "align-self: center"};

  @media screen and (max-width: 768px) {
    width: 100%;
    ${(props) => props.mmt && `margin-top: ${props.mmt}px`};
  }
`

export const ProjectDetailContainerStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: ${(props) => (props.mb ? props.mb : "40px")};
`

export const BulletWrapper = styled.ul`
  ${(props) => props.mt && `margin-top: ${props.mt}px`};
  > li {
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 300)};
    color: ${(props) => (props.color ? props.color : "#000")};
    font-size: 16px;
    line-height: 22px;
  }
  ${(props) => props.listStyleType && `list-style-type: ${props.listStyleType}`};
`

export const Gap = styled.div`
  ${(props) => props.isHideDesktop && "display: none"};
  height: ${(props) => props.height && `${props.height}px;`};

  @media screen and (max-width: 414px) {
    ${(props) => props.mHeight && `height: ${props.mHeight}px`};
    ${(props) => props.isHideMobile && "display: none"};
    ${(props) => props.isHideDesktop && "display: block"};
  }
`

export const FullImageWrapper = styled.div`
  width: 100%;
  @media screen and (max-width: 1024px) {
    align-items: center;
    flex-direction: column;
    width: calc(100% - 140px);
    padding: 0 70px;
  }
`

export const Input = styled.input`
  /* display: inline-block; */
  width: 100%;
  height: 40px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;

  font-size: 16px;
  line-height: 22px;
  padding: 10px;
`

export const FooterRefWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  margin-right: -10%;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const RiRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => (props.gap ? `${props.gap}px` : "20px")};

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  ${(props) => props.mt && `margin-top: ${props.mt}px`};
`

export const SectionTitleV2 = styled.div`
  font-family: Source Code Pro, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 31px;
  color: #000000;
  ${(props) => props.mt && `margin-top: ${props.mt}px`};
`

export const ClickableSectionTitleV2 = styled.a`
  font-family: Source Code Pro, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 31px;
  color: #00818a;
  cursor: pointer;
  text-decoration: underline;
  ${(props) => props.mt && `margin-top: ${props.mt}px`};
`
