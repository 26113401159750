import React from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import YouTube from "react-youtube"

import {
  ContentTextNormal,
  BackIconWrapper,
  ProjectTextTitle,
  ContentTextBold,
  ProjectImageWrapper,
  BulletWrapper,
  ProjectDetailContainerStyled,
  Gap,
} from "../../../components/shared/shared"
import { Button, ButtonGroup } from "../../../components/button/button"

import backIcon from "../../../img/back-icon.svg"
import hybridgeLogo from "../../../img/hybridge/hybridge-logo.png"
import hybridgePlatformImg from "../../../img/hybridge/hybridge-platform.svg"
import serviceDesignPackageImg from "../../../img/hybridge/service-design-package.png"
// import futureImpactImg from "../../../img/hybridge/future-impact.svg"
import futureImpactImg from "../../../img/hybridge/future-impact-v2.png"
import appLogo from "../../../img/hybridge/app-logo.svg"
import businessModelCanvasImg from "../../../img/hybridge/business-model-canvas.png"
// import blueOceanStrategyImg from "../../../img/hybridge/blue-ocean-strategy.png"

import { HybridgeProcess } from "./process"
import "./youtube.css"

const ContentWrapper = styled.div`
  width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 140px);
    padding: 0 70px;
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 52px);
    padding: 0 27px;
  }
`

const FutureImpactWrapper = styled.div`
  width: 1120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: calc(100% - 140px);
    padding: 0 70px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: calc(100% - 52px);
    padding: 0 27px;
  }
`

export const FutureImpactImageWrapper = styled.img`
  ${(props) => props.mt && `margin-top: ${props.mt}px`};
  width: 700px;
  object-fit: none;

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0;
    object-fit: cover;
  }
`

const FutureImpactContentWrapper = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`

const AppLogoWrapper = styled.div`
  width: 350px;
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`

export const AppLogoImage = styled.img`
  ${(props) => props.mt && `margin-top: ${props.mt}px`};
  width: 88px;
  height: 88px;
  object-fit: cover;

  @media screen and (max-width: 414px) {
    width: 54px;
    height: 54px;
  }
`

const Hybridge = () => {
  const [showProcess, setShowProcess] = React.useState(true)
  const navigate = useNavigate()

  return (
    <>
      <BackIconWrapper onClick={() => navigate(-1)}>
        <img src={backIcon} width={8} height={15} alt="Back Icon" />
        <ContentTextNormal>Projects</ContentTextNormal>
      </BackIconWrapper>
      <ProjectDetailContainerStyled>
        <ContentWrapper>
          <ProjectTextTitle>Hybridge - | 2021</ProjectTextTitle>
          <ContentTextNormal>
            The project prompt is to create an innovative social network offer that is feasible, fills a
            user-stakeholder need, and addresses a market opportunity that so far remains unexplored by real and/or
            potential competitors.
          </ContentTextNormal>
          <ContentTextBold>
            Project Contributors: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>Lina Edris, Em Cote, and Thiti Kapadia</ContentTextNormal>
          </ContentTextBold>
          <ButtonGroup>
            <Button Lato onClick={() => setShowProcess(true)} bgColor={showProcess ? "#00818a" : "#919191"}>
              Process
            </Button>
            <Button
              Lato
              isActive={!showProcess}
              bgColor={showProcess ? "#919191" : "#00818a"}
              onClick={() => setShowProcess(false)}>
              Outcome
            </Button>
          </ButtonGroup>
          {showProcess ? (
            <HybridgeProcess />
          ) : (
            <>
              <ProjectImageWrapper src={hybridgeLogo} alt="Hybridge Logo" />
              <ContentTextNormal>
                Designers who double as users have a particularly intense connection to their projects, and that’s
                exactly the case with Team Hybridge. After having spent much of their degree online, this group gathered
                a unique breadth of knowledge surrounding the available tools for virtual group development. Heavy
                research into global trends and the professional SNS marketplace revealed that the best possible course
                of action would be to face their largest personal pain head-on: the loneliness and other issues that
                come with working remotely.
              </ContentTextNormal>
              <ContentTextBold>The Problem</ContentTextBold>
              <ContentTextNormal>
                Across the world in 2020, 48.7 million workers moved to working remotely [1], and over 97% want to
                continue working that way [2]. Dozens of industries are happy to do so, but most of the existing tools
                and platforms being used were appropriated during the pandemic and therefore don't fulfill all the
                necessary requirements of this paradigm shift. By filling in the whitespace and targeting hybrid workers
                specifically, Team Hybridge has an enormous opportunity to address their needs in real time rather than
                patching an existing product.
              </ContentTextNormal>
              <ContentTextBold>The Solution</ContentTextBold>
              <ContentTextNormal>
                Introducing “Hybridge,” a brand new, professional social networking system and collaboration apparatus
                made specifically for remote and hybrid workers. The platform focuses on three core values: flexibility,
                performance, and rapport. With three main features and two secondary, Hybridge functions cross-platform
                and connects co-workers through specifically-catered hybrid tools, easing the transition from virtual to
                in-person. This isn’t about “online” or IRL; it’s about enabling that choice and improving their
                experience in the process.
              </ContentTextNormal>
              <ContentTextBold>The Hybridge Platform</ContentTextBold>
              <ProjectImageWrapper mt="-20" mb="10" src={hybridgePlatformImg} alt="Hybridge Platform" />
              <BulletWrapper>
                <li>
                  After creating your personal Hybridge profile, you can carry it with you from company to company,
                  acting as a living resume, updating skills, projects, and more.
                </li>
                <li>
                  Upon first opening the Hybridge platform, users will see their virtual workspace. Just like everyone
                  has different working styles, this area is completely customizable with a little something we like to
                  call widgets; tiny tools that serve specific purposes.
                </li>
              </BulletWrapper>
              <ContentTextBold>Service Design Package</ContentTextBold>
              <ProjectImageWrapper src={serviceDesignPackageImg} alt="Service Design Package" />
              <ContentTextBold color="#00818A">Calendar</ContentTextBold>
              <ContentTextNormal>Hybridge’s Calendar feature encompasses 3 sections:</ContentTextNormal>
              <BulletWrapper mt="-18">
                <li>MeCal: focuses on sorting your personal events</li>
                <li>WeCal: dives into group meetings, such as projects, meetings, etc.</li>
                <li>Hello Hybrid: allows hybrid users to can indicate their availability (virtually or in-person)</li>
              </BulletWrapper>
              <ContentTextBold mt="-15" color="#00818A">
                CommLab
              </ContentTextBold>
              <ContentTextNormal>
                CommLab is where an organization’s methods of communication are designed. Every project or department
                can create their own group (a special page in which users can find everything they may need for great
                project facilitation). Here, you can also find the ProDev Groups, where organizations can host external
                training in specific skills or programs.
              </ContentTextNormal>
              <ContentTextBold color="#00818A">The Bridge</ContentTextBold>
              <ContentTextNormal>
                The Bridge is the part of the Hybridge that aims to both encourage new connections between co-workers
                and added ease of transitioning to a hybrid workstyle; this includes a guide to becoming a full
                Hybridger, Virtual Working Buddies tab for increased collaboration, and ridesharing facilitation to help
                people get to the office.
              </ContentTextNormal>
              <ContentTextBold>Future Impact</ContentTextBold>
              <FutureImpactWrapper>
                <FutureImpactContentWrapper>
                  <ContentTextNormal>
                    Although this SNS has simply been a project, Team Nomadic believes it has an incredibly solid
                    mission and purpose that could allow it to live past the walls of SCAD.
                    <br />
                    <br />
                    We’d love for Hybridge to improve the relationship between co-workers and their companies by
                    minimizing the pains of remote work, from reduced rapport to poorly-managed scheduling; eventually,
                    this will usher in a new paradigm of normalized remote and hybrid work.
                  </ContentTextNormal>
                  <AppLogoWrapper>
                    <AppLogoImage src={appLogo} alt="Service Design Package App Logo" />
                    <ContentTextBold>
                      “The only app around made to specifically facilitate modern hybrid and remote work”
                    </ContentTextBold>
                  </AppLogoWrapper>
                </FutureImpactContentWrapper>
                <FutureImpactImageWrapper src={futureImpactImg} alt="Future Impact" />
              </FutureImpactWrapper>
              <ContentTextBold>Hybridge Business Model Canvas</ContentTextBold>
              <ProjectImageWrapper src={businessModelCanvasImg} alt="Business Model Canvas" />
              <Gap height="0" />
              {/* <ContentTextBold>Blue Ocean Strategy - Competitor Complementor Composite</ContentTextBold>
              <ProjectImageWrapper src={blueOceanStrategyImg} alt="Blue Ocean Strategy" /> */}
              <YouTube videoId="K8VZtRKDHnc" containerClassName="youtubeContainer" />
            </>
          )}
          <ButtonGroup>
            <Button
              Lato
              onClick={() => {
                setShowProcess(!showProcess)
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                })
              }}
              bgColor="#919191">
              {!showProcess ? "View Process" : "View Outcome"}
            </Button>
          </ButtonGroup>
        </ContentWrapper>
      </ProjectDetailContainerStyled>
    </>
  )
}

export default Hybridge
