import styled, { keyframes, css } from "styled-components"

import { DesktopPage1AnimateDuration } from "./v2-constants"

// ========================= Desktop Page 01 Zone ===========================
export const PieLegAnimation = keyframes`
  0% {
    transform: translateX(200px);
    opacity: 0;
    -webkit-transform: translateX(200px);
    -webkit-opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
    -webkit-transform: translateX(0);
    -webkit-opacity: 1;
  }
`

export const PieLegAnimationWrapper = styled.div`
  animation: ${(props) =>
    props.isAnimateDesktop
      ? css`
          ${DesktopPage1AnimateDuration} ${PieLegAnimation}
        `
      : ""};
  -webkit-animation: ${(props) =>
    props.isAnimateDesktop
      ? css`
          ${DesktopPage1AnimateDuration} ${PieLegAnimation}
        `
      : ""};
`

export const PieBodyAnimation = keyframes`
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`

export const PieBodyAnimationWrapper = styled.div`
  animation: ${(props) =>
    props.isAnimateDesktop
      ? css`
          ${DesktopPage1AnimateDuration} ${PieBodyAnimation}
        `
      : ""};
`

export const DesktopTextAnimationPage01 = keyframes`
  0% {
    transform: translateY(250px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`
// ========================= End of Desktop Page 01 ===========================

// ========================= Desktop Page 02 Zone ===========================
export const PieFaceAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`

export const PieFaceAnimationWrapper = styled.div`
  &.animate {
    animation: ${({ isAnimateDesktop }) =>
      isAnimateDesktop
        ? css`
            ${DesktopPage1AnimateDuration} ${PieFaceAnimation}
          `
        : ""};
  }
`

export const PieExpDesWrapper01AnimationPage02 = keyframes`
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`

export const PieExpDesWrapper02AnimationPage02 = keyframes`
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`

export const DesktopTextAnimationPage02 = keyframes`
  0% {
    opacity: 1;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`
// ========================= End of Desktop Page 02 ===========================

// ========================= Desktop Page 03 Zone ===========================
export const ProjectAnimationLeft = keyframes`
  0% {
    opacity: 1;
    transform: translateX(-120px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`

export const ProjectAnimationRight = keyframes`
  0% {
    opacity: 1;
    transform: translateX(120px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`
// ========================= End of Desktop Page 03 ===========================

// ========================= Desktop Page 04 Zone ===========================
export const DesktopTextAnimationPage04 = keyframes`
  0% {
    opacity: 1;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`

export const DesktopFooterAnimationLeft = keyframes`
  0% {
    opacity: 1;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`

export const DesktopFooterAnimationRight = keyframes`
  0% {
    opacity: 1;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`
// ========================= End of Desktop Page 04 ===========================
