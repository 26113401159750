import React from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import {
  ContentTextNormal,
  BackIconWrapper,
  ProjectTextTitleV2,
  ProjectSubTitleV2,
  ContentTextBold,
  ProjectImageWrapper,
  ProjectTextTitle,
  ProjectDetailTitleV2,
  ProjectBackgroundWrapper,
  ProjectDetailContainerStyled,
  Gap,
  ContentTextNormalWithBold,
  Input,
  OutsideImageWrapper,
  ContentTextNormalLink,
  FooterRefWrapper,
  RiRowWrapper,
} from "../../../components/shared/shared"
import { ButtonGroup, Button } from "../../../components/button/button"
import { TagWrapper, Tag } from "../../../components/tag/tag"
import { ModalWrapper, ModalContent, ModalCloseButton } from "../../../components/modal/modal"

import RentSpreeInsurance13 from "./rsp-insurance-1.3"

import backIcon from "../../../img/back-icon.svg"
import riGetQuoteImage from "../../../img/rsp-renter-insurance/get-quote-image.svg"
import riLowMidFiInfoImage from "../../../img/rsp-renter-insurance/low-mid-fi-info-image.png"
import riLowMidFiCustomizeImage from "../../../img/rsp-renter-insurance/low-mid-fi-customize-image.png"
import riLowMidFiCheckOutImage from "../../../img/rsp-renter-insurance/low-mid-fi-check-out-image.png"
import riPrototype01 from "../../../img/rsp-renter-insurance/ri-prototype-01.gif"
import riPrototype02 from "../../../img/rsp-renter-insurance/ri-prototype-02.gif"
import riDesignUiComponentImage from "../../../img/rsp-renter-insurance/ri-design-ui-components.svg"
import riHighFiUiImage from "../../../img/rsp-renter-insurance/ri-high-fi-ui.svg"
import riFinalUiDesignImage from "../../../img/rsp-renter-insurance/ri-final-ui-design.svg"
import riPieTesting01Image from "../../../img/rsp-renter-insurance/ri-pie-testing-01.svg"
import riPieTesting02Image from "../../../img/rsp-renter-insurance/ri-pie-testing-02.svg"
import riSummaryOfProcessImage from "../../../img/rsp-renter-insurance/summary-of-ri-process.svg"
import riRoadmapImage from "../../../img/rsp-renter-insurance/roadmap-img.svg"
import riProjectDetailBg from "../../../img/rsp-renter-insurance/project-detail-bg.svg"
import riUserFlowImage from "../../../img/rsp-renter-insurance/user-flow-img.svg"
import riSUSImg from "../../../img/rsp-renter-insurance/sus-img.svg"
import riNNGroupImg from "../../../img/rsp-renter-insurance/nn-group-img.svg"
import riGoalBgImg from "../../../img/rsp-renter-insurance/goal-bg-img.svg"
import riSuccessMetricsImg from "../../../img/rsp-renter-insurance/success-metrics-img.svg"
import riJiraImg from "../../../img/rsp-renter-insurance/jira-img.svg"
import riCodeImg from "../../../img/rsp-renter-insurance/code-img.svg"
import riFooterBgImg from "../../../img/rsp-renter-insurance/footer-bg-img.svg"
import riFooterImprovementBgImg from "../../../img/rsp-renter-insurance/footer-improvement-bg-img.svg"

import { PASSCODE } from "../rsp-insurance/constants"

const ContentWrapper = styled.div`
  width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  scroll-behavior: smooth;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 140px);
    padding: 0 70px;
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 52px);
    padding: 0 27px;
  }
`

const RentSpreeInsurance = ({ isFirstVersion }) => {
  const navigate = useNavigate()
  const [userPasscode, setUserPasscode] = React.useState(null)
  const [isPasscodeCorrect, setIsPasscodeCorrect] = React.useState(false)
  const [passcodeIncorrect, setPasscodeIncorrect] = React.useState(false)
  const [passcodeErrorMsg, setPasscodeErrorMsg] = React.useState(false)
  const [showFirstVersion, setShowFirstVersion] = React.useState(isFirstVersion)

  const validatePassword = () => {
    if (userPasscode === PASSCODE) {
      setIsPasscodeCorrect(true)
    } else {
      setPasscodeIncorrect(true)
      if (!userPasscode) setPasscodeErrorMsg("Required.")
      else setPasscodeErrorMsg("That's not the correct passcode, Please try again.")
    }
  }

  if (!isPasscodeCorrect)
    return (
      <ModalWrapper>
        <ModalContent>
          <ModalCloseButton onClick={() => navigate(-1)}>&times;</ModalCloseButton>
          <ProjectTextTitle mt="0">Password Required</ProjectTextTitle>
          <ContentTextNormal>
            The password is required to view this project due to the{" "}
            <ContentTextNormalWithBold>Non-discloser Agreement.</ContentTextNormalWithBold> Please enter the password or
            contact <a href="mailto: pirpiep@gmail.com">pirpiep@gmail.com</a> for inquiries.
          </ContentTextNormal>
          <Gap height="10" />
          <ContentTextNormal>
            Password <span style={{ color: "#F15555", fontSize: "16px", fontWeight: "normal" }}>*</span>
          </ContentTextNormal>
          <Gap height="5" />
          <Input
            type="password"
            name="passcode"
            id="passcode"
            onChange={({ target: { value } }) => setUserPasscode(value)}
          />
          <Gap height="5" />
          {passcodeIncorrect && <ContentTextNormalWithBold color="red">{passcodeErrorMsg}</ContentTextNormalWithBold>}
          <Gap height="20" />
          <Button Lato onClick={validatePassword}>
            Enter
          </Button>
        </ModalContent>
      </ModalWrapper>
    )

  const renderFirstVersion = () => {
    return (
      <>
        <ProjectBackgroundWrapper img={riProjectDetailBg} mt="20" mBgColor="#1b7bd4">
          <ProjectDetailTitleV2>Renters Insurance 1.0 | January 2021</ProjectDetailTitleV2>
          <ContentTextNormalWithBold width="80%" color="#fff" mWidth="100%">
            When I first got to work at RentSpree, Rentspree planned to launch the Renters Insurance feature in early
            2021. The project was halfway planned, so I got to help with user flows and user interfaces in the designing
            phase. Also, I created a prototype for an internal usability test before it goes to the production phase.
            Apart from that, I also contribute in setting up a hypothesis matrix, goal and event tracking, which are
            very important for studying users’ post-production behavior.
          </ContentTextNormalWithBold>
          <ContentTextBold color="#fff">
            Project Contributors: &nbsp;&nbsp;&nbsp;
            <ContentTextNormalWithBold color="#fff">RentSpree Team Momo (BKK)</ContentTextNormalWithBold>
          </ContentTextBold>
        </ProjectBackgroundWrapper>

        <ContentTextBold mt="20">Summary of my process:</ContentTextBold>
        <ProjectImageWrapper src={riSummaryOfProcessImage} alt="RSP Renter Insurance Process" />

        <ContentTextBold mt="20">Understanding user flow</ContentTextBold>
        <OutsideImageWrapper>
          <ProjectImageWrapper src={riUserFlowImage} alt="RSP Renter Insurance User Flow" />
        </OutsideImageWrapper>

        <ContentTextBold mt="20">Low-mid fi interface design</ContentTextBold>
        <ProjectImageWrapper src={riGetQuoteImage} alt="RSP Renter Insurance Get Quote Low-mid fi UI" />
        <RiRowWrapper gap="0">
          <ProjectImageWrapper
            width="33%"
            src={riLowMidFiInfoImage}
            alt="RSP Renter Insurance Info page Low-mid fi UI"
          />
          <ProjectImageWrapper
            width="33%"
            src={riLowMidFiCustomizeImage}
            alt="RSP Renter Insurance Customize page Low-mid fi UI"
          />
          <ProjectImageWrapper
            width="34%"
            src={riLowMidFiCheckOutImage}
            alt="RSP Renter Insurance Check Out Low-mid fi UI"
          />
        </RiRowWrapper>
        <ContentTextBold mt="20">Prototype for internal usability test</ContentTextBold>
        <RiRowWrapper gap="0">
          <ProjectImageWrapper width="50%" src={riPrototype01} alt="RSP Renter Insurance Prototype 01" />
          <ProjectImageWrapper width="50%" src={riPrototype02} alt="RSP Renter Insurance Prototype 02" />
        </RiRowWrapper>

        <OutsideImageWrapper>
          <ProjectImageWrapper mt="20" src={riSUSImg} alt="RSP Renter Insurance System Usability Scale (SUS)" />
        </OutsideImageWrapper>

        <ContentTextBold mt="20">Designing UI components</ContentTextBold>
        <ProjectImageWrapper src={riDesignUiComponentImage} alt="RSP Renter Insurance Design UI Components" />
        <ContentTextBold mt="20">High-fi interface design</ContentTextBold>
        <ProjectImageWrapper src={riHighFiUiImage} alt="RSP Renter Insurance High Fi UI" />

        <OutsideImageWrapper>
          <ProjectImageWrapper mt="20" src={riNNGroupImg} alt="RSP Renter Insurance NN Group" />
        </OutsideImageWrapper>

        <ContentTextBold mt="20">Final interface design</ContentTextBold>
        <ContentTextNormal mt="-10">
          Created final interfaces in both web and mobile, making sure we have everything ready for front-end developers
          to implement.
        </ContentTextNormal>
        <ProjectImageWrapper src={riFinalUiDesignImage} alt="RSP Renter Insurance Final UI Design" />

        <ProjectBackgroundWrapper img={riGoalBgImg} mt="20" height="430px" mBgColor="#e6e6e6">
          <ProjectDetailTitleV2 Lato color="#000">
            Goal
          </ProjectDetailTitleV2>
          <ContentTextBold>Hypothesis, Experiment & Event tracking</ContentTextBold>
          <ContentTextNormalWithBold width="40%" color="#000" mWidth="100%" weight="300">
            Designers and product owner work together to set up the goal of the feature perfomance. After that we then
            create experiments and hypothesis matrix to measure the performance, to prove the goal result, and to study
            users’ behavior for future improvements.
          </ContentTextNormalWithBold>
          <ContentTextNormalWithBold weight="300">Tool used: Amplitude Product Analytics</ContentTextNormalWithBold>
        </ProjectBackgroundWrapper>

        <ProjectImageWrapper mt="20" src={riSuccessMetricsImg} alt="RSP Renter Insurance Sucess Metrics" />

        <ContentTextBold mt="20">User Stories</ContentTextBold>
        <ContentTextNormal mt="-10">
          After we finished all the design, we create user stories on Jira and prioritize it for software engineers to
          work on.
        </ContentTextNormal>
        <RiRowWrapper gap="0">
          <ProjectImageWrapper width="54%" src={riJiraImg} alt="RSP Renter Insurance Jira" />
          <ProjectImageWrapper width="46%" src={riCodeImg} alt="RSP Renter Insurance Code" />
        </RiRowWrapper>
        <ContentTextNormalLink
          mt="-20"
          isDisabledUnderline
          href="https://www.atlassian.com/agile/tutorials/sprints"
          target="_blank">
          Image source: atlassian
        </ContentTextNormalLink>

        <ContentTextBold mt="20">Testing</ContentTextBold>
        <ContentTextNormal mt="-10">
          After developers implement it, as a designer, I have to test the sandbox to ensure that the flow/experience is
          right and the interfaces, including colors, fonts, margins, and spaces are all pixel-perfect before they go
          public to the production site.
        </ContentTextNormal>
        <ProjectImageWrapper src={riPieTesting01Image} alt="RSP Renter Insurance Pie Testing 01" />
        <ProjectImageWrapper src={riPieTesting02Image} alt="RSP Renter Insurance Pie Testing 02" />
      </>
    )
  }

  return (
    <>
      <BackIconWrapper onClick={() => navigate(-1)}>
        <img src={backIcon} width={8} height={15} alt="Back Icon" />
        <ContentTextNormal>Projects</ContentTextNormal>
      </BackIconWrapper>
      <ProjectDetailContainerStyled mb="0">
        <ContentWrapper>
          <ProjectTextTitleV2 SourceCodePro fontSize="40">
            2021
          </ProjectTextTitleV2>
          <ProjectSubTitleV2>RentSpree: Renters Insurance</ProjectSubTitleV2>
          <ContentTextNormal>
            In the past few years, Rentspree has been in the real estate market and is known as one of the best
            screening platforms in the States. We try very hard to stay on top of the market, keep up with the
            competitors, and deliver the best product to our users. We have many features and projects lined up in the
            roadmap that we think will benefit users, including Renters Insurance.
          </ContentTextNormal>
          <ContentTextNormal>
            Renters Insurance was one of the top priorities in the project roadmap, and it is essential for both tenants
            and landlords in terms of protecting their properties and belongings. And also benefit to RentSpree since it
            is another way to generate revenue.
          </ContentTextNormal>
          <ContentTextBold>
            Objective: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>To create a ready-to-ship Renters Insurance feature</ContentTextNormal>
          </ContentTextBold>
          <ContentTextNormal color="#8A0000">
            **This project contains sensitive data. It cannot be shared to anywhere and can only be viewed with
            permission on www.pirpie.com.**
          </ContentTextNormal>
          <TagWrapper>
            <Tag>user flow</Tag>
            <Tag>prototyping</Tag>
            <Tag>interface design</Tag>
            <Tag>usability test</Tag>
            <Tag>hypotheses</Tag>
            <Tag>metrics and goals</Tag>
            <Tag>event tracking</Tag>
            <Tag>user story</Tag>
            <Tag>jira</Tag>
            <Tag>figma</Tag>
            <Tag>amplitude</Tag>
            <Tag>testing</Tag>
            <Tag>user research</Tag>
            <Tag>scrum</Tag>
            <Tag>sprint</Tag>
            <Tag>agile process</Tag>
          </TagWrapper>
          <ContentTextBold>Project Roadmap:</ContentTextBold>
          <ProjectImageWrapper mt="20" src={riRoadmapImage} alt="RSP Renter Insurance Roadmap" />
          <ContentTextBold mt="20">Quick Jump:</ContentTextBold>

          <ButtonGroup mColumn>
            <Button
              Lato
              onClick={() => setShowFirstVersion(true)}
              bgColor={showFirstVersion ? "#00818a" : "#fff"}
              border={showFirstVersion ? "none" : "1px solid #00818A"}
              color={showFirstVersion ? "#fff" : "#00818A"}>
              Renters Insurance 1.0
            </Button>
            <Button
              Lato
              bgColor={showFirstVersion ? "#fff" : "#00818a"}
              border={showFirstVersion ? "1px solid #00818A" : "none"}
              color={showFirstVersion ? "#00818A" : "#fff"}
              onClick={() => setShowFirstVersion(false)}>
              Renters Insurance 1.3
            </Button>
          </ButtonGroup>

          {showFirstVersion ? renderFirstVersion() : <RentSpreeInsurance13 />}

          <ProjectBackgroundWrapper
            img={showFirstVersion ? riFooterBgImg : riFooterImprovementBgImg}
            mt="20"
            height="448px"
            mBgColor="#63676D">
            {showFirstVersion ? (
              <ContentTextNormalWithBold width="52%" color="#fff" mWidth="100%">
                After launch, we monitor the feature performance to see our users’ behavior and traffic. After a few
                months, as a designer, we will gather those data, summarize, and present it to the team for future
                improvements.
              </ContentTextNormalWithBold>
            ) : (
              <ContentTextNormalWithBold width="100%" color="#fff" mWidth="100%">
                You have reached the end of the project. Thanks for watching :)
              </ContentTextNormalWithBold>
            )}
            <ContentTextNormalWithBold width="80%" color="#fff" mWidth="100%">
              {showFirstVersion
                ? "Click the link below to see the feature improvement"
                : "Please keep in mind that because this project is under NDA and contains confidential company data, it cannot be shared or viewed without permission."}
            </ContentTextNormalWithBold>
            <Button
              Lato
              width="222"
              mt="10"
              bgColor="#fff"
              border="none"
              color="#919191"
              boxSizing="border-box"
              onClick={() => {
                if (showFirstVersion) {
                  setShowFirstVersion(!showFirstVersion)
                  window.scrollTo({
                    top: 750,
                    behavior: "smooth",
                  })
                } else {
                  navigate("/projects")
                }
              }}>
              {showFirstVersion ? "Renters Insurance 1.3" : "Explore more projects"}
            </Button>
            <Button
              Lato
              width={showFirstVersion ? "178" : "284"}
              bgColor="rgba(255, 255, 255, 0.08)"
              border="1px solid #C4C4C4"
              color="#C4C4C4"
              boxSizing="border-box"
              backdropFilter="blur(4px)"
              onClick={() => {
                if (showFirstVersion) {
                  navigate("/projects")
                } else {
                  setShowFirstVersion(true)
                  window.scrollTo({
                    top: 750,
                    behavior: "smooth",
                  })
                }
              }}>
              {showFirstVersion ? "Back to Projects" : "Back to Renters Insurance 1.0"}
            </Button>
            {showFirstVersion && (
              <FooterRefWrapper>
                <ContentTextNormalLink
                  mt="205"
                  isDisabledUnderline
                  color="#fff"
                  href="https://unsplash.com"
                  target="_blank">
                  All image source: Unsplash
                </ContentTextNormalLink>
              </FooterRefWrapper>
            )}
          </ProjectBackgroundWrapper>
        </ContentWrapper>
      </ProjectDetailContainerStyled>
    </>
  )
}

export default RentSpreeInsurance
