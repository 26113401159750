import React from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import {
  ContentTextNormal,
  BackIconWrapper,
  ProjectTextTitle,
  ContentTextBold,
  ProjectImageWrapper,
  ProjectDetailContainerStyled,
  FullImageWrapper,
  Gap,
} from "../../../components/shared/shared"
import { Button } from "../../../components/button/button"

import backIcon from "../../../img/back-icon.svg"
import toeImg01 from "../../../img/through-our-eyes/toe-pic-01.png"
import toeImg02 from "../../../img/through-our-eyes/toe-pic-02.png"

const ContentWrapper = styled.div`
  width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 140px);
    padding: 0 70px;
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 52px);
    padding: 0 27px;
  }
`

const ThroughOurEyes = () => {
  const navigate = useNavigate()

  return (
    <>
      <BackIconWrapper onClick={() => navigate(-1)}>
        <img src={backIcon} width={8} height={15} alt="Back Icon" />
        <ContentTextNormal>Projects</ContentTextNormal>
      </BackIconWrapper>
      <ProjectDetailContainerStyled>
        <ContentWrapper>
          <ProjectTextTitle>Through Our Eyes: Through Service Design Perspective | 2020</ProjectTextTitle>
          <ContentTextNormal>
            This book was created to show the service design process and tools that we used, to give audiences a sense
            of service design and system thinking through our perspectives.
          </ContentTextNormal>
          <ContentTextNormal>
            The book contains the approach that we used and 7 chapters of service design experiments, such as service
            encounter map, service blueprint, user journey map, service context, service experiences, and system
            thinking.
          </ContentTextNormal>
          <ContentTextBold>
            Project Contributors: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>Yongqifang Hu and Shijie Luo</ContentTextNormal>
          </ContentTextBold>
          <Gap height="5" />
        </ContentWrapper>
        <FullImageWrapper>
          <ProjectImageWrapper src={toeImg01} alt="Pie Through Our Eyes 01" />
          <Gap height="10" />
          <ProjectImageWrapper src={toeImg02} alt="Pie Through Our Eyes 02" />
        </FullImageWrapper>
        <Gap height="20" />
        <Button Lato width="162" mWidth="100" target="_blank" href="https://issuu.com/pirpie/docs/through_our_eyes">
          View full book
        </Button>
      </ProjectDetailContainerStyled>
    </>
  )
}

export default ThroughOurEyes
