import React from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import {
  ContentTextNormal,
  BackIconWrapper,
  ProjectTextTitle,
  ContentTextBold,
  ProjectImageWrapper,
  BulletWrapper,
  ProjectDetailContainerStyled,
} from "../../../components/shared/shared"

import backIcon from "../../../img/back-icon.svg"
import sCadProLogo from "../../../img/plnat-riverside-district/scadpro.svg"
import plantRiversideLogo from "../../../img/plnat-riverside-district/plant-riverside-logo.svg"
import pieFriendsPlantRiversideImg from "../../../img/plnat-riverside-district/pie-friends-plant-riverside.svg"
import designInsightConceptImg from "../../../img/plnat-riverside-district/design-insight-concept.svg"
import designCycleImg from "../../../img/plnat-riverside-district/design-cycle.svg"
import exampleFontImg from "../../../img/plnat-riverside-district/example-font.svg"
import examplePrototypeImg from "../../../img/plnat-riverside-district/example-prototype.svg"
import overviewImg from "../../../img/plnat-riverside-district/overview.svg"
import contributorsImg from "../../../img/plnat-riverside-district/contributors.svg"

const ContentWrapper = styled.div`
  width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 140px);
    padding: 0 70px;
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 52px);
    padding: 0 27px;
  }
`

const LastImageWrapper = styled.div`
  width: 1120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: calc(100% - 140px);
    padding: 0 70px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: calc(100% - 52px);
    padding: 0 27px;
  }
`

const PlantRiverside = () => {
  const navigate = useNavigate()

  return (
    <>
      <BackIconWrapper onClick={() => navigate(-1)}>
        <img src={backIcon} width={8} height={15} alt="Back Icon" />
        <ContentTextNormal>Projects</ContentTextNormal>
      </BackIconWrapper>
      <ProjectDetailContainerStyled>
        <ContentWrapper>
          <ProjectTextTitle>Plant Riverside District | 2019</ProjectTextTitle>
          <ContentTextNormal>
            This is a class collaboration project with The Kessler Collection. To develop concepts for a branded visitor
            experience in a high-end hotel and museum called the Plant Riverside
          </ContentTextNormal>
          <ContentTextBold>
            Project Contributors: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>
              Amelie Wang, Bridget Weitzel, Chelsea McLaurin, Daniel MY Kim, Kun Wang, Lauren Wooddy, Liam Maheu, Linxi
              Wang, Madison Sacramone, Maria Primera Darwich, Mattie Overmyer, Natalia Uribe, Paloma Ningthoujam,
              Sheridan Markham, Vidihika Khanna, Zachary Taylor, and professor Christine Fish
            </ContentTextNormal>
          </ContentTextBold>
          <ProjectImageWrapper src={sCadProLogo} alt="SCADPRO LOGO" />
          <ProjectImageWrapper src={plantRiversideLogo} alt="PLANT RIVERSIDE LOGO" />
          <ContentTextBold>Project introduction:</ContentTextBold>
          <ContentTextNormal mt="-10">
            Invited in by the Kessler Collection, this SCADPro team was brought on to assist in the development of the
            Plant Riverside District, with a specific focus placed on the Generator Hall. Utilizing the extensive array
            of backgrounds, knowledge, and expertise of the team members, the SCADPro team researched and worked
            extensively to set project goals.
          </ContentTextNormal>
          <ContentTextNormal mt="-10">
            Together with the guidance and insight obtained from the Kessler Collection, the team was able to determine
            the focus of the project was to create a cohesive and emotional experience that continuously engages users,
            while simultaneously supporting the business of the Plant Riverside District. Emphasizing the idea of
            experience, the team was able to use this notion as a driving factor in the creation of four concepts
            relating to the following structure: branding for key touchpoints, meaningful history exploration,
            interactive and engaging moments and digital technologies. With these specific concepts, the SCADPro team
            conceived and delivered unique and innovative solutions that can be implemented to elevate the experience at
            Plant Riverside District.
          </ContentTextNormal>
          <ProjectImageWrapper src={pieFriendsPlantRiversideImg} alt="PIE and Friends PLANT RIVERSIDE" />
          <ContentTextNormal textAlign="right">
            **Due to the Non-Disclosure Agreement, project details can not be revealed**
          </ContentTextNormal>
          <ContentTextBold>My role:</ContentTextBold>
          <ContentTextNormal mt="-10">
            Early on in this project, we established a communal work style. With such a wide variety of skill sets and
            backgrounds, we wanted to ensure that individuals were able to bounce around and apply expertise where it
            was needed. With this being said, I have associated in a variety of design fields throughout this project. I
            started off as a contributor to the research phase.
          </ContentTextNormal>
          <ContentTextNormal mt="-10">
            After given the project brief and focus features from the Kessler collection, I have conducted, together
            with team members, primary and secondary research using various methodology; Site visite, observation,
            interviews, secondary research on trends and luxury elements, and specified stakeholders.
          </ContentTextNormal>
          <ContentTextNormal mt="-10">
            We have decided to follow the Double Diamond framework, which allows us to divide the process according to
            the way of thinking involved within each stage;
          </ContentTextNormal>
          <BulletWrapper mt="-18">
            <li>Discover customer problems</li>
            <li>Define specific customer problems</li>
            <li>Develop potential solutions to these customers</li>
            <li>Deliver feasible and viable solutions to these customer problems</li>
          </BulletWrapper>
          <ProjectImageWrapper mt="-30" src={designInsightConceptImg} alt="Design Insights and Concepts" />
          <ContentTextNormal mt="-20">
            After analyzing and synthesizing research findings, we reframed insights into “How Might We” questions to
            turn those challenges into opportunities for design. Together with personas and journey maps, we created
            personas that illustrate the behavior, attitude, skills, and contexts of current and potential users and
            journey maps to educate stakeholders as to what customers perceive.
          </ContentTextNormal>
          <ContentTextNormal mt="-10">
            For the purpose of concept development, we expressed our ideas through many kinds of design thinking methods
            to shape our concept.
          </ContentTextNormal>
          <ContentTextNormal mt="-10">
            We used the 5 E's framework to design meaningful services or experiences, whether digital or physical. It is
            an integrative model that adds coherence, elegance, and excitement for building holistic and meaningful
            experiences.
          </ContentTextNormal>
          <ProjectImageWrapper src={designCycleImg} alt="Design Cycle" />
          <ContentTextNormal mt="-10">
            Once we had narrowed down the concepts we wanted to produce, I became a graphic designer for this project by
            directing a branding guideline, color schemes, created graphic elements for each sub-team, and generated the
            process book. By the end, this had been the most rewarding and educational project I had ever been on.
          </ContentTextNormal>
          <ContentTextBold>Example of my contributions for project outcome</ContentTextBold>
          <ProjectImageWrapper src={exampleFontImg} alt="Pie's Font" />
          <ProjectImageWrapper src={examplePrototypeImg} alt="Pie's Prototype" />
          <LastImageWrapper>
            <ProjectImageWrapper src={overviewImg} alt="Overview" />
            <ProjectImageWrapper src={contributorsImg} alt="Contributors" />
          </LastImageWrapper>
        </ContentWrapper>
      </ProjectDetailContainerStyled>
    </>
  )
}

export default PlantRiverside
