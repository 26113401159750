import React, { useState } from "react"
import styled from "styled-components"

import { useLocation } from "react-router-dom"

import { NavControllerStyled, Nav, NavLink, Bars, NavMobileMenu, PieMobileNavNameStyled } from "./styles"
import newLogo from "../../img/about-v2/new-logo.png"

import { PROJECT_PATHS_NAME } from "../navbar/constants"

const PieLogo = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 10px;
  @media screen and (max-width: 768px) {
    margin-top: 14px;
    margin-left: 22px;
  }
`

const Navbar = () => {
  const { pathname } = useLocation()
  const [mobileNav, toggleMobileNav] = useState(false)
  const [isBelongToProject, setActiveProject] = useState(false)
  const handleMobileNav = () => toggleMobileNav(!mobileNav)

  React.useEffect(() => {
    const isWithinProjectPath = PROJECT_PATHS_NAME.indexOf(pathname) !== -1
    if (isWithinProjectPath) setActiveProject(true)
    else setActiveProject(false)
  }, [pathname])

  return (
    <NavControllerStyled>
      <Nav id="desktop-nav">
        <NavLink to="/">
          <PieLogo src={newLogo} alt="Pie's Logo" />
          <span style={{ fontSize: "16px", fontWeight: "900", color: "#00818A" }}>Pie Prapawuttikul</span>
        </NavLink>
        <NavLink ml="-120" to="/projects" isbelongtoproject={isBelongToProject ? isBelongToProject : null}>
          [ projects ]
        </NavLink>
        <NavLink to="/contact">say hi</NavLink>
      </Nav>
      <Nav id="mobile-nav">
        <NavLink onClick={() => toggleMobileNav(false)} to="/">
          <PieMobileNavNameStyled>Pie</PieMobileNavNameStyled>
        </NavLink>
        <Bars onClick={handleMobileNav} />
        <NavMobileMenu active={mobileNav}>
          <NavLink
            to="/projects"
            isbelongtoproject={isBelongToProject ? isBelongToProject : null}
            onClick={handleMobileNav}>
            [ projects ]
          </NavLink>
          <NavLink to="/contact" onClick={handleMobileNav}>
            say hi
          </NavLink>
        </NavMobileMenu>
      </Nav>
    </NavControllerStyled>
  )
}

export default Navbar
