import React from "react"

import {
  ContentTextNormal,
  ContentTextBold,
  ProjectImageWrapper,
  BulletWrapper,
  ProjectBackgroundWrapper,
  ProjectDetailTitleV2,
  ContentTextNormalWithBold,
  RiRowWrapper,
  OutsideImageWrapper,
} from "../../../components/shared/shared"
import { Row } from "../../../components/shared/layout"

import rspUserSurvey01 from "../../../img/rsp-insurance-research/user-survey-01.png"
import rspUserSurvey02 from "../../../img/rsp-insurance-research/user-survey-02.png"
import rspUserSurvey03 from "../../../img/rsp-insurance-research/user-survey-03.png"
import rspUserSurvey04 from "../../../img/rsp-insurance-research/user-survey-04.png"
import rspUserSurvey05 from "../../../img/rsp-insurance-research/user-survey-05.png"
import rspUserSurvey06 from "../../../img/rsp-insurance-research/user-survey-06.png"
import rspRevisitUserJourney from "../../../img/rsp-insurance-research/revisit-user-journey.svg"
import rspAllUxAssessmentImg from "../../../img/rsp-insurance-research/ux-assessment-all-imgs.png"
import rspUxUiImprovement01 from "../../../img/rsp-insurance-research/ux-ui-improvement-01.svg"
import rspUxUiImprovement02 from "../../../img/rsp-insurance-research/ux-ui-improvement-02.png"
import rspFeatureImprovement from "../../../img/rsp-insurance-research/feature-improvement.svg"
import rspExperimentSummary from "../../../img/rsp-insurance-research/experiment-summary.png"
import rspSummaryOfRiResearchProcess from "../../../img/rsp-insurance-research/summary-of-ri-research-process.svg"
import riProjectDetailBg from "../../../img/rsp-insurance-research/project-detail-bg.svg"
import riProblem01Img from "../../../img/rsp-insurance-research/problem-01-img.svg"
import riProblem02Img from "../../../img/rsp-insurance-research/problem-02-img.svg"
import riProblem03Img from "../../../img/rsp-insurance-research/problem-03-img.svg"
import riUserstandingUserImg from "../../../img/rsp-insurance-research/understanding-user-img.svg"
import riAmazonGiftCard01Img from "../../../img/rsp-insurance-research/amazon-gift-card-01-img.svg"
import riAmazonGiftCard02Img from "../../../img/rsp-insurance-research/amazon-gift-card-02-img.svg"
import riUxAssessmentBgImg from "../../../img/rsp-insurance-research/ux-assessment-bg-img.svg"

const RentSpreeInsurance13 = () => {
  return (
    <>
      <ProjectBackgroundWrapper img={riProjectDetailBg} mt="20" mBgColor="#1b7bd4">
        <ProjectDetailTitleV2>Renters Insurance 1.3 | April 2021</ProjectDetailTitleV2>
        <ContentTextNormalWithBold width="80%" color="#fff" mWidth="100%">
          Rentspree launched Renters Insurance feature in the early 2021, but did not reach expected performance. I, as
          a designer lead for this project, have to study users behavior, identify problems and find potential solutions
          for this feature.
        </ContentTextNormalWithBold>
        <ContentTextBold width="75%" color="#fff" mWidth="100%">
          Objectives: &nbsp;&nbsp;&nbsp;
          <ContentTextNormalWithBold color="#fff">
            To assess the low adoption rate of RentSpree's Renters Insurance feature by conducting a comprehensive
            analysis to understand how the feature is resonating throughout all touchpoints in the user experience.
            Mixed method research encompassing analysis of:
          </ContentTextNormalWithBold>
          <BulletWrapper mt="10" color="#fff" fontWeight="400">
            <li>Feature performance;</li>
            <li>Surveys targeting different user profiles;</li>
            <li>UX for Renters Insurance feature;</li>
            <li>Marketing efforts;</li>
            <li>User journeys;</li>
          </BulletWrapper>
        </ContentTextBold>
        <ContentTextBold color="#fff">
          Project Contributors: &nbsp;&nbsp;&nbsp;
          <ContentTextNormalWithBold color="#fff">
            RentSpree Team ADA (BKK) and UX researcher Julia Geld (USA)
          </ContentTextNormalWithBold>
        </ContentTextBold>
      </ProjectBackgroundWrapper>

      <ContentTextBold mt="20">Summary of my process:</ContentTextBold>
      <ProjectImageWrapper src={rspSummaryOfRiResearchProcess} alt="RSP Renter Insurance Research Process" />

      <ContentTextBold mt="20">
        Problem: &nbsp;&nbsp;&nbsp;<ContentTextNormal>Low awareness, adoption and huge user drop off</ContentTextNormal>
      </ContentTextBold>
      <RiRowWrapper gap="40">
        <ProjectImageWrapper width="40%" src={riProblem01Img} alt="RSP Renter Insurance Problem 01" />
        <ProjectImageWrapper width="60%" src={riProblem02Img} alt="RSP Renter Insurance Problem 02" />
      </RiRowWrapper>
      <ProjectImageWrapper mt="-10" src={riProblem03Img} alt="RSP Renter Insurance Problem 03" />

      <OutsideImageWrapper>
        <ProjectImageWrapper mt="20" src={riUserstandingUserImg} alt="RSP Renter Insurance Userstanding User" />
      </OutsideImageWrapper>

      <RiRowWrapper gap="0" mt="20">
        <ProjectImageWrapper width="51.5%" src={riAmazonGiftCard01Img} alt="RSP Renter Insurance Amazon Gift Card 01" />
        <ProjectImageWrapper width="48.5%" src={riAmazonGiftCard02Img} alt="RSP Renter Insurance Amazon Gift Card 02" />
      </RiRowWrapper>

      <ContentTextBold mt="0">Image speaks louder than words</ContentTextBold>
      <ContentTextNormal mt="-10">
        The survey was attached to the footer of the report email, which is the most sent email on RentSpree.
      </ContentTextNormal>
      <BulletWrapper mt="-20">
        <li>Experiment 1: Survey footer without reward.</li>
        <li>
          Experiment 2: Survey footer with reward - The first two experiments were not practical; we still got 0
          responses after a week. So we decided to change the image.
        </li>
        <li>
          Experiment 3: Survey footer with reward and gift card image - we had our first response within an hour and 81
          responses within ten days.
        </li>
      </BulletWrapper>

      <ContentTextBold mt="20">User reseach and survey analysis</ContentTextBold>
      <Row gap="0" m768 mt="-10">
        <ProjectImageWrapper width="50%" src={rspUserSurvey01} alt="RSP Renter Insurance Research (User Survey 01)" />
        <ProjectImageWrapper width="50%" src={rspUserSurvey02} alt="RSP Renter Insurance Research (User Survey 02)" />
      </Row>
      <Row gap="0" m768 mt="-20">
        <ProjectImageWrapper width="50%" src={rspUserSurvey03} alt="RSP Renter Insurance Research (User Survey 03)" />
        <ProjectImageWrapper width="50%" src={rspUserSurvey04} alt="RSP Renter Insurance Research (User Survey 04)" />
      </Row>
      <Row gap="0" m768 mt="-20">
        <ProjectImageWrapper width="50%" src={rspUserSurvey05} alt="RSP Renter Insurance Research (User Survey 05)" />
        <ProjectImageWrapper width="50%" src={rspUserSurvey06} alt="RSP Renter Insurance Research (User Survey 06)" />
      </Row>
      <ContentTextNormal>
        After understanding users’ perception on renters insurance and RentSpree’s feature, we revisit the user journey
        to see what stage does renters insurance take part of the rental application.
      </ContentTextNormal>
      <ContentTextBold>Revisit user journey</ContentTextBold>
      <ContentTextNormal mt="-10">
        Mapping out user journey is one of the most important part, it allows us to see and understand the whole process
        from a broader visions and to see connections between each process. The journeys include user journey on both
        tenant (mint) and agent (blue), RentSpree journey (cobalt), and the potential solutions (orange).
      </ContentTextNormal>
      <ProjectImageWrapper src={rspRevisitUserJourney} alt="RSP Renter Insurance Research (Revisit User Journey)" />
      <ContentTextNormal>
        From mapping journey, it shows that Renters Insurance feature isn't integrated to RentSpree's core application
        flow, low adoption can be a reflex of this disintegration to the rental journey, where we are unable to fulfill
        users needs at the right moment.
      </ContentTextNormal>

      <ProjectBackgroundWrapper img={riUxAssessmentBgImg} mt="20" height="354px">
        <ProjectDetailTitleV2 Lato>UX Assessment</ProjectDetailTitleV2>
        <ContentTextNormalWithBold width="100%" color="#fff" mWidth="100%">
          The objective was to review the Renters Insurance feature's current design in order to identify bottlenecks
          and friction points providing feedback and recommendations on how to improve user experience. This stage
          requires UX writer to revise the current copy of user interfaces and marketing emails descripton.
        </ContentTextNormalWithBold>
      </ProjectBackgroundWrapper>
      <ProjectImageWrapper
        mtPercent="-26%"
        mmt="0"
        src={rspAllUxAssessmentImg}
        alt="RSP Renter Insurance Research (UX Assessment)"
      />

      <ContentTextBold mt="20">UX/UI Improvements</ContentTextBold>
      <BulletWrapper mt="-10">
        <li>We made changes based on our discussion with design team and UX writer. </li>
        <li>
          We create more entry points for user to discover Renters Insurance to increase awareness of the feature. This
          part includes the goal, hypothesis, and event tracking planning.
        </li>
      </BulletWrapper>
      <ProjectImageWrapper
        mt="-10"
        src={rspUxUiImprovement01}
        alt="RSP Renter Insurance Research (UX UI Improvement 01)"
      />

      <ContentTextNormal>
        3 new entry points: we create experiment to see which channel has the highest traffic and adoption
      </ContentTextNormal>
      <ProjectImageWrapper src={rspUxUiImprovement02} alt="RSP Renter Insurance Research (UX UI Improvement 02)" />

      <ContentTextBold mt="20">Experiment Summary</ContentTextBold>
      <ProjectImageWrapper src={rspExperimentSummary} alt="RSP Renter Insurance Research (Experiment Summary)" />

      <ContentTextBold mt="20">Future Improvements - Integrate Renters Insurance with other features</ContentTextBold>
      <ContentTextNormal mt="-10">
        To make a seamless rental experience, renters insurance needs to be introduced at the right step. From the
        journey above, there are several potential integrations with the current RentSpree feature which are
        Accept/Deny, E-Sign, and the upcoming Listing Syndication feature.
      </ContentTextNormal>
      <ProjectImageWrapper src={rspFeatureImprovement} alt="RSP Renter Insurance Research (Feature Improvement)" />
    </>
  )
}

export default RentSpreeInsurance13
