import React from "react"
import styled from "styled-components"
import { useLocation } from "react-router-dom"

import Navbar from "./components/navbar-v2"
// import { Footer, FooterOwnerName, FooterDeveloperName } from "./components/footer/footer"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
// import About from "./containers/about/about"
import AboutV2 from "./containers/about/about-v2"
// import Contact from "./containers/contact/contact"
import ContactV2 from "./containers/contact/contact-v2"
import Projects from "./containers/project/project"
import ServiceExplorationProject from "./containers/project/service-exploration/service-exploration"
import PlantRiversideProject from "./containers/project/plant-riverside/plant-riverside"
import HybridgeProject from "./containers/project/hybridge/hybridge"
import Jordi from "./containers/project/jordi/jordi"
import ThroughOurEyes from "./containers/project/through-our-eyes/through-our-eyes"
import ServiceDesignExperiment from "./containers/project/service-design-experiment/service-design-experiment"
import RentSpreeRentalIndustry from "./containers/project/rsp-rental-industry/rsp-rental-industry"
// import RentSpreeInsurance from "./containers/project/rsp-insurance/rsp-insurance"
import RentSpreeInsurance from "./containers/project/rsp-insurance/rsp-insurance-1.0"
import RentSpreePersonas from "./containers/project/rsp-personas/rsp-personas-v2"
import Domo from "./containers/project/domo/domo"
import CoFarm from "./containers/project/co-farm/co-farm"
import Athena from "./containers/project/athena/athena"
import NotFound from "./containers/not-found/not-found"

export const ScreenStyled = styled.div`
  font-family: Lato, sans-serif;
  font-style: normal;
`

const ScrollToTop = () => {
  const { pathname } = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function App() {
  return (
    <ScreenStyled>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" exact element={<AboutV2 />} />
          {/* <Route path="/" exact element={<About />} /> */}
          <Route path="/projects" exact element={<Projects />} />
          <Route path="/project/exploration" element={<ServiceExplorationProject />} />
          <Route path="/project/plant-riverside" element={<PlantRiversideProject />} />
          <Route path="/project/hybridge" element={<HybridgeProject />} />
          <Route path="/project/jordi" element={<Jordi />} />
          <Route path="/project/toe" element={<ThroughOurEyes />} />
          <Route path="/project/service-design-experiment" element={<ServiceDesignExperiment />} />
          <Route path="/project/rsp-rental-industry" element={<RentSpreeRentalIndustry />} />
          <Route path="/project/rsp-renter-insurance" element={<RentSpreeInsurance isFirstVersion={true} />} />
          <Route path="/project/rsp-insurance-research" element={<RentSpreeInsurance />} />
          <Route path="/project/rsp-personas" element={<RentSpreePersonas />} />
          <Route path="/project/domo" element={<Domo />} />
          <Route path="/project/athena" element={<Athena />} />
          <Route path="/project/cofarm" element={<CoFarm />} />
          {/* <Route path="/about" element={<About />} /> */}
          <Route path="/contact" element={<ContactV2 />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* TODO: If enable, Please adjust footer position to relative then handle in each project and contact pages */}
        {/* <Footer>
          <FooterOwnerName>&copy; Pie Prapawuttikul • 2022 • All Rights Reserved</FooterOwnerName>
          <FooterDeveloperName>{"</> Coding by MeBank"}</FooterDeveloperName>
        </Footer> */}
      </Router>
    </ScreenStyled>
  )
}

export default App
