import styled from "styled-components"

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => (props.gap ? `${props.gap}px` : "20px")};
  ${(props) => props.mt && `margin-top: ${props.mt}px`};

  @media screen and (max-width: 1024px) {
    flex-direction: ${(props) => (props.m768 ? "row" : "column")};
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    ${(props) => props.mmt && `margin-top: ${props.mmt}px`};
  }
`
