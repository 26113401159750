import styled, { keyframes, css } from "styled-components"

import { DesktopPage1AnimateDuration } from "../about/v2-constants"

// ========================= Contact Contact  Page 01 Zone ===========================
export const PieFaceAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const PieFaceAnimationWrapper = styled.div`
  animation: ${(props) =>
    props.isAnimateDesktop
      ? css`
          ${DesktopPage1AnimateDuration} ${PieFaceAnimation}
        `
      : ""};
`

export const TextLeftAnimation = keyframes`
  0% {
    opacity: 1;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`

export const TextRightAnimation = keyframes`
  0% {
    opacity: 1;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`
// ========================= End of Contact Desktop Page 01 ===========================
