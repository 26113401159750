import styled from "styled-components"

export const ModalWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* Fallback color */
  /* background-color: rgb(0, 0, 0); */
  /* Black w/ opacity */
  /* background-color: rgba(0, 0, 0, 0.4); */
  z-index: 1; /* Sit on top */
  border: 1px solid #fff;
`

export const ModalContent = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  border: 1px solid #e5e5e5;
  background: #ffffff;
  border-radius: 5px;
  margin: auto;
  padding: 30px 50px;
  background-color: #fefefe;

  @media screen and (max-width: 414px) {
    width: 280px;
  }

  @media screen and (max-width: 375px) {
    width: 200px;
  }
`

export const ModalCloseButton = styled.span`
  display: flex;
  justify-content: flex-end;
  color: #aaa;
  font-size: 28px;
  font-weight: 300;
  margin-right: -30px;
  margin-top: -20px;

  &:hover,
  &:focus {
    text-decoration: none;
    cursor: pointer;
  }
`
