import React from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import {
  ContentTextNormal,
  BackIconWrapper,
  ProjectTextTitle,
  ContentTextBold,
  ProjectImageWrapper,
  ProjectDetailContainerStyled,
} from "../../../components/shared/shared"

import backIcon from "../../../img/back-icon.svg"
import designOutcome from "../../../img/service-exploration/design-outcome.svg"
import summaryOfProcessServiceExplorationImage from "../../../img/service-exploration/summary-of-svc-explore-process.png"

const ContentWrapper = styled.div`
  width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 140px);
    padding: 0 70px;
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 52px);
    padding: 0 27px;
  }
`

const ServiceExploration = () => {
  const navigate = useNavigate()

  return (
    <>
      <BackIconWrapper onClick={() => navigate(-1)}>
        <img src={backIcon} width={8} height={15} alt="Back Icon" />
        <ContentTextNormal>Projects</ContentTextNormal>
      </BackIconWrapper>
      <ProjectDetailContainerStyled>
        <ContentWrapper>
          <ProjectTextTitle>Service Exploration: Savannah Riverboat Cruise | 2019</ProjectTextTitle>
          <ContentTextNormal>
            This project allows us to observe, explore, and discover the Savannah Riverboat Cruises tour. We analyzed
            the information we have gathered, Synthesized data into different maps, then find opportunity spaces for
            future improvements.
          </ContentTextNormal>
          <ContentTextBold>
            Project Contributors: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>Yongqifang Hu, Jun Zhao, and Jiajin Lan</ContentTextNormal>
          </ContentTextBold>
          <ContentTextBold>
            Objective: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>
              To create a customer journey map, service offering map, service encounter map, ecosystem map, and service
              blueprint.
            </ContentTextNormal>
          </ContentTextBold>
          <ContentTextBold>
            Process: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>
              We did an observation by taking photos and notes in every process from booking to the end, and we also
              experienced the tour ourselves. After that, we wrote down the flows of every steps, sketched, and put it
              all together for finalize.
            </ContentTextNormal>
          </ContentTextBold>
          <ContentTextBold>Summary of my process:</ContentTextBold>
          <ProjectImageWrapper
            src={summaryOfProcessServiceExplorationImage}
            alt="Summary of Service Exploration Process"
          />
          <ContentTextBold>Design Outcome:</ContentTextBold>
          <ProjectImageWrapper src={designOutcome} alt="Pie's Design Outcome" />
        </ContentWrapper>
      </ProjectDetailContainerStyled>
    </>
  )
}

export default ServiceExploration
