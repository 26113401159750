import React from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import {
  ContentTextNormal,
  BackIconWrapper,
  ProjectTextTitleV2,
  ProjectSubTitleV2,
  ProjectImageWrapper,
  ProjectDetailContainerStyled,
  OutsideImageWrapper,
} from "../../../components/shared/shared"
import { TagWrapper, Tag } from "../../../components/tag/tag"
import { Row } from "../../../components/shared/layout"

import backIcon from "../../../img/back-icon.svg"
import coFarmProjectCoverImg from "../../../img/co-farm/cofarm-proj-cover-img.png"
import coFarmInfoWrapUpImg from "../../../img/co-farm/cofarm-info-wrap-up-img.png"
import coFarmInsightDetailImg from "../../../img/co-farm/cofarm-insight-details-img.png"
import coFarmDesignThinkingImg from "../../../img/co-farm/cofarm-design-thinking-img.png"
import coFarmInfoArchitectureImg from "../../../img/co-farm/cofarm-info-architecture-img.png"
import coFarmSketchesImg from "../../../img/co-farm/cofarm-sketches-img.png"
import coFarmStyleGuideImg from "../../../img/co-farm/cofarm-style-guide-img.png"
import coFarmHighFidelityImg from "../../../img/co-farm/cofarm-high-fidelity.png"
import coFarmAppMainScreenImg from "../../../img/co-farm/cofarm-app-main-screen-img.gif"
import coFarmAppSignupImg from "../../../img/co-farm/cofarm-app-signup-img.gif"

const ContentWrapper = styled.div`
  width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  margin-bottom: -40px;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 140px);
    padding: 0 70px;
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 52px);
    padding: 0 27px;
  }
`

const CoFarm = () => {
  const navigate = useNavigate()

  return (
    <>
      <BackIconWrapper onClick={() => navigate(-1)}>
        <img src={backIcon} width={8} height={15} alt="Back Icon" />
        <ContentTextNormal>Projects</ContentTextNormal>
      </BackIconWrapper>
      <ProjectDetailContainerStyled>
        <ContentWrapper>
          <ProjectTextTitleV2 SourceCodePro fontSize="40">
            2022
          </ProjectTextTitleV2>
          <ProjectSubTitleV2>CoFarm: Agritourism Application Design</ProjectSubTitleV2>
          <TagWrapper mt="-10">
            <Tag>ux/ui design</Tag>
            <Tag>mobile application</Tag>
            <Tag>end-to-end product design</Tag>
            <Tag>user research</Tag>
            <Tag>design system</Tag>
          </TagWrapper>

          <OutsideImageWrapper>
            <ProjectImageWrapper mt="20" src={coFarmProjectCoverImg} alt="CoFarm Project Poster" />
          </OutsideImageWrapper>

          <ProjectSubTitleV2 mt="20">Background</ProjectSubTitleV2>
          <ContentTextNormal mt="-10">
            Thailand has an abundant natural resources, making agriculture the country's main economic industry. Thai
            people's way of life has been linked to agriculture for a long time. However, farmers are still one of the
            poorest occupation in Thailand since the prices of food and goods are lowered by middlemen. Agriculture is
            largely inaccessible to the general public due to a lack of assistance and being disregarded.
          </ContentTextNormal>
          <ContentTextNormal mt="-10">
            CoFarm is created as a mobile application to support farmers and bridges people and agriculture together. It
            allows user to have direct purchase with farmers, and allows user to have an agricultural experience with no
            limitation on time and place.
          </ContentTextNormal>

          <OutsideImageWrapper mt="20">
            <ProjectImageWrapper src={coFarmInfoWrapUpImg} alt="CoFarm Inforamtion Wrap up" />
          </OutsideImageWrapper>

          <OutsideImageWrapper mt="20">
            <ProjectImageWrapper src={coFarmInsightDetailImg} alt="CoFarm Insight Details" />
          </OutsideImageWrapper>

          <OutsideImageWrapper mt="20">
            <ProjectImageWrapper src={coFarmDesignThinkingImg} alt="CoFarm Design Thinking" />
          </OutsideImageWrapper>

          <OutsideImageWrapper mt="20">
            <ProjectImageWrapper src={coFarmInfoArchitectureImg} alt="CoFarm Info Architecture" />
          </OutsideImageWrapper>

          <OutsideImageWrapper mt="20">
            <ProjectImageWrapper src={coFarmSketchesImg} alt="CoFarm Sketches" />
          </OutsideImageWrapper>

          <OutsideImageWrapper mt="20">
            <ProjectImageWrapper src={coFarmStyleGuideImg} alt="CoFarm Style Guide" />
          </OutsideImageWrapper>

          <OutsideImageWrapper mt="20">
            <ProjectImageWrapper src={coFarmHighFidelityImg} alt="CoFarm High Fidelity" />
          </OutsideImageWrapper>

          <Row>
            <ProjectImageWrapper src={coFarmAppMainScreenImg} alt="CoFarm App Main Screen" />
            <ProjectImageWrapper src={coFarmAppSignupImg} alt="CoFarm App Signup" />
          </Row>
        </ContentWrapper>
      </ProjectDetailContainerStyled>
    </>
  )
}

export default CoFarm
