import React from "react"

import {
  ContentTextNormal,
  ContentTextBold,
  ProjectImageWrapper,
  BulletWrapper,
} from "../../../components/shared/shared"
import { Row } from "../../../components/shared/layout"

import remoteWorker from "../../../img/hybridge/process/remote-worker.png"
import hybridgeInsights from "../../../img/hybridge/process/insights.png"
import userPersonas from "../../../img/hybridge/process/user-personas.png"
import conceptDevelopment from "../../../img/hybridge/process/concept-development.png"
import featureDevelopment from "../../../img/hybridge/process/feature-development.png"
import prototype01 from "../../../img/hybridge/process/prototype-01.png"
import prototype02 from "../../../img/hybridge/process/prototype-02.png"
import testingImage from "../../../img/hybridge/process/testing.png"
import testingAnalysisImage from "../../../img/hybridge/process/testing-analysis.png"
import summaryOfHybridgeProcessImage from "../../../img/hybridge/process/summary-of-hybridge-process.png"

export const HybridgeProcess = () => {
  return (
    <>
      <ContentTextBold>Summary of my process:</ContentTextBold>
      <ProjectImageWrapper src={summaryOfHybridgeProcessImage} alt="Summary of Hybridge Process" />
      <ContentTextBold>#1 Topic Generation</ContentTextBold>
      <ContentTextNormal>
        Needless to say, the global pandemic changed a staggering amount of everyone's lives, perhaps our social lives
        most of all. Many people are having to re-establish lost relationships as we speak; talk about the perfect time
        to assign an Social Network Service as a project... Keeping the current situation in mind, our team did a deep
        dive into global trends, particularly those involving social and relationship-based aspects.
      </ContentTextNormal>
      <ContentTextNormal>
        The pandemic hit the economy particularly massively, but other areas were hit on a smaller scale as well. We
        observed some industries gain higher demand, while others lost market shares. Some creative industries were able
        to make a shift into a new adaptive style to accommodate current strains and limitations pushing the boundaries
        to find new ways of living and working.
      </ContentTextNormal>
      <ContentTextBold color="#00818A">Remote worker</ContentTextBold>
      <ContentTextNormal>
        We ran a deeper research on a ‘remote worker’ to gain understanding on the context, explore the current remote
        working trend, and collect all the supported data from many sources.
      </ContentTextNormal>
      <ProjectImageWrapper src={remoteWorker} alt="Hybride's Hybridge Remote Worker Image" />
      <ContentTextBold>#2 Primary Research and Analysis</ContentTextBold>
      <ContentTextNormal>
        To better-understand our potential users, extensive primary research was performed, in which we surveyed and
        interviewed potential customers who are working remotely in various sectors globally.
      </ContentTextNormal>
      <ContentTextNormal>
        To better-understand our potential users, extensive primary research was performed, in which we surveyed and
        interviewed potential customers who are working remotely in various sectors globally.
      </ContentTextNormal>
      <ContentTextNormal mt="-10">We ran a survey and a follow up interviews, and the findings are:</ContentTextNormal>
      <BulletWrapper mt="-18">
        <li>Most participants are between 26-35 years old</li>
        <li>80% of participants are working remote</li>
        <li>60% of participants have been working remote for 1-2 years</li>
        <li>More than 20 applications/programs have been used in order to work remotely</li>
        <li>Etc.</li>
      </BulletWrapper>
      <ContentTextNormal mt="-15">
        We gathered all the data and started analyze our insights to build our user personas and develop business model
      </ContentTextNormal>
      <ContentTextBold color="#00818A">Insights</ContentTextBold>
      <ProjectImageWrapper src={hybridgeInsights} alt="Hybride's Hybridge Insights Image" />
      <ContentTextBold color="#00818A">Final insights</ContentTextBold>
      <BulletWrapper mt="-5">
        <li>
          Digital natives who make up the majority of the remote workforce can and want to stay remote after having
          started due to the pandemic.
        </li>
        <li>
          Working remotely allows you to have access to talent from all over the world, but that also means there will
          be issues with time differences and delays.
        </li>
        <li>
          Even though all work can have negative aspects, remote workers recognize that an essential element of good
          teamwork is being able to socialize with your colleagues, but remote work can prevent relationships from
          forming and make people feel more lonely.
        </li>
        <li>
          Strict time management is essential to leading a balanced remote work life and prevents you from working more
          than you need to.
        </li>
        <li>
          Even though collaboration takes up the majority of many remote workers' days, communication and scheduling is
          delayed and messy due to the current remote working system.
        </li>
        <li>
          Remote workers are seeking more flexibility from their employers, allowing them to have an easier balance
          between their two lives and enjoy their home life.
        </li>
        <li>
          Working in a hybrid style is optimal so you can experience the best of both worlds and improve communication
          of complex ideas.
        </li>
        <li>
          Technology should be used to improve existing collaboration tools, which currently vary widely and are
          inconsistent from company to company, resulting in delayed idea management and tech issues.
        </li>
      </BulletWrapper>
      <ContentTextBold color="#00818A" mt="-10">
        User Personas
      </ContentTextBold>
      <ProjectImageWrapper src={userPersonas} alt="User Personas Image" />
      <ContentTextNormal>
        After we got all insights and user personas, we continued to do the competitor analysis to see the
        strenghts/weakness and our potential services.
      </ContentTextNormal>
      <ContentTextBold>#3 Concept Development</ContentTextBold>
      <ContentTextNormal>
        Diving into ideation and brainstorming, our team broke down some potential features we would be pursuing,
        including sound reasoning and possible twists that could set them apart
      </ContentTextNormal>
      <ContentTextNormal>
        After developing our potential feature ideas, they were then mapped out on a 2x2 Impact VS Time canvas based on
        our users' feedback.
      </ContentTextNormal>
      <ContentTextNormal>
        It was clear that some features were certainly more important than others, but the ones that would be the most
        impactful would definitely take the most time to develop.
      </ContentTextNormal>
      <ProjectImageWrapper src={conceptDevelopment} alt="Hybride's Concept Development" width="571px" center />
      <ContentTextBold color="#00818A">Features Development</ContentTextBold>
      <ProjectImageWrapper src={featureDevelopment} alt="Hybride's User Personas Image" />
      <ContentTextBold>#4 Prototype</ContentTextBold>
      <Row>
        <ProjectImageWrapper src={prototype01} alt="Hybride's Prototype Image 01" />
        <ProjectImageWrapper src={prototype02} alt="Hybride's Prototype Image 02" />
      </Row>
      <ContentTextBold>#5 Testing</ContentTextBold>
      <ProjectImageWrapper src={testingImage} alt="Hybride's Testing Image" />
      <ContentTextBold color="#00818A">Testing Analysis</ContentTextBold>
      <ProjectImageWrapper src={testingAnalysisImage} alt="Hybride's Testing Analysis Image" />
      <ContentTextNormal>
        After we analyzed our test results, we were then define the good and the bad. Then we make changes until we have
        our final resuts! :)
      </ContentTextNormal>
    </>
  )
}
