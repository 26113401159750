import styled from "styled-components"

export const Tag = styled.div`
  font-family: Source Code Pro, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #00818a;
  padding: 5px 15px;
  border-radius: 5px;
  background: rgba(0, 129, 138, 0.1);
`

export const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  ${(props) => props.mt && `margin-top: ${props.mt}px`};
`
