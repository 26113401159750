import styled, { css } from "styled-components"

import {
  DesktopTextAnimationPage01,
  PieExpDesWrapper01AnimationPage02,
  PieExpDesWrapper02AnimationPage02,
  DesktopTextAnimationPage02,
  ProjectAnimationLeft,
  ProjectAnimationRight,
  DesktopTextAnimationPage04,
  DesktopFooterAnimationLeft,
  DesktopFooterAnimationRight,
} from "./v2-animation-styles"

import { DesktopPage1AnimateDuration, DesktopPage3AnimateDuration } from "./v2-constants"

export const ContentWrapper = styled.div`
  font-family: Source Code Pro, sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 120px;
  margin-bottom: 120px;

  ${(props) => props.hideDesktop && `display: none`};

  @media screen and (max-width: 600px) {
    ${(props) => props.hideMobile && `display: none`};
    ${(props) => props.showMobile && `display: flex`};
    ${(props) => props.mPadding && `padding: ${props.mPadding}`};
    ${(props) => props.mPadding && "width: calc(100% - 90px)"};
    margin-top: 50px;
  }
`

export const ProjectTeaserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 600px;
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : "0px")};

  @media screen and (max-width: 820px) {
    ${(props) => props.mt820 && `margin-top: ${props.mt820}px`};
  }

  @media screen and (max-width: 768px) {
    ${(props) => props.mt768 && `margin-top: ${props.mt768}px`};
  }

  /* @media screen and (max-width: 600px) {
    flex-direction: column;
  } */
`

export const ProjectTeaserLeftWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.width ? `${props.width};` : "56%;")};
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : "0px")};
  background: ${(props) => (props.bg ? `${props.bg};` : "transparent;")};

  &.animate {
    animation: ${({ isAnimateDesktop, animateRight }) =>
      isAnimateDesktop
        ? css`
            ${DesktopPage3AnimateDuration} ${animateRight ? ProjectAnimationRight : ProjectAnimationLeft}
          `
        : ""};
  }
`

export const TeaserImageWrapper = styled.img`
  object-fit: ${(props) => (props.objectFit ? `${props.objectFit}` : "cover")};
  width: 100%;
  height: ${(props) => (props.height ? `${props.height}px` : "600px")};
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : "0px")};
  background: ${(props) => (props.bg ? `${props.bg};` : "transparent;")};
`

export const ProjectTeaserRightWrapper = styled.div`
  width: ${(props) => (props.width ? `${props.width};` : "44%;")};
  color: #fff;
  padding: ${(props) => (props.padding ? `${props.padding}` : "110px 52px;")};
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : "0px")};
  background: ${(props) => (props.bg ? `${props.bg};` : "#000;")};

  @media screen and (max-width: 820px) {
    padding: 60px 52px;
  }

  @media screen and (max-width: 600px) {
    width: -webkit-fill-available;
    padding: 20px;
  }

  &.animate {
    animation: ${({ isAnimateDesktop, animateLeft }) =>
      isAnimateDesktop
        ? css`
            ${DesktopPage3AnimateDuration} ${animateLeft ? ProjectAnimationLeft : ProjectAnimationRight}
          `
        : ""};
  }
`

export const PieBodyImageWrapper = styled.img`
  position: absolute;
  object-fit: cover;
  width: 393px;
  height: 500px;
  left: 0;
  margin-top: 200px;

  @media screen and (max-width: 820px) {
    margin-top: 240px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    ${(props) => props.hideMobile && `display: none`};
    ${(props) => props.showMobile && `display: flex`};
    position: relative;
    margin-top: 170px;
    margin-top: 20px;
  }

  @media screen and (max-width: 414px) {
    height: 520px;
  }
`

export const PieLegImageWrapper = styled.img`
  position: absolute;
  object-fit: cover;
  width: 254px;
  height: 400px;
  right: 0;
  margin-top: -20px;

  @media screen and (max-width: 600px) {
    ${(props) => props.hideMobile && `display: none`};
    ${(props) => props.showMobile && `display: flex`};
  }
`

export const ArrowWrapper = styled.img`
  object-fit: contain;
  width: 18px;
  height: 120px;
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : "0px")};

  animation: ${({ isAnimateDesktop, firstArrow }) =>
    isAnimateDesktop && firstArrow
      ? css`
          ${DesktopPage1AnimateDuration} ${DesktopTextAnimationPage01}
        `
      : ""};

  &.animate {
    animation: ${({ isAnimateDesktop, secondArrow }) =>
      isAnimateDesktop && secondArrow
        ? css`
            ${DesktopPage1AnimateDuration} ${DesktopTextAnimationPage02}
          `
        : ""};
  }

  &.animate {
    animation: ${({ isAnimateDesktop, thirdArrow }) =>
      isAnimateDesktop && thirdArrow
        ? css`
            ${DesktopPage1AnimateDuration} ${DesktopTextAnimationPage04}
          `
        : ""};
  }
`

export const PieFaceImageWrapper = styled.img`
  object-fit: contain;
  width: 200px;
  height: 200px;
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : "120px")};

  @media screen and (max-width: 600px) {
    margin-top: 30px;
  }
`

export const PieDesWrapper = styled.div`
  width: 393px;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: 0.01em;
  color: #000000;
  padding-left: 520px;
  margin-top: 200px;

  @media screen and (max-width: 820px) {
    width: 320px;
    font-size: 30px;
    margin-top: 320px;
    padding-left: 440px;
  }

  @media screen and (max-width: 600px) {
    font-size: 24px;
    padding-left: 0;
    margin-top: -20px;
    line-height: 30px;
  }

  animation: ${(props) =>
    props.isAnimateDesktop
      ? css`
          ${DesktopPage1AnimateDuration} ${DesktopTextAnimationPage01}
        `
      : ""};
`

export const PieExpWrapper = styled.div`
  width: 543px;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.1em;
  color: #00818a;
  padding-left: 670px;
  margin-top: 30px;

  @media screen and (max-width: 1024px) {
    width: 439px;
    font-size: 24px;
    padding-left: 566px;
  }

  @media screen and (max-width: 820px) {
    width: 340px;
    font-size: 18px;
    padding-left: 465px;
  }

  @media screen and (max-width: 768px) {
    width: 300px;
    font-size: 18px;
    padding-left: 425px;
  }

  @media screen and (max-width: 600px) {
    width: 330px;
    font-size: 16px;
    padding-left: 10px;
    margin-top: 20px;
    line-height: 26px;
    letter-spacing: 0;
  }

  animation: ${(props) =>
    props.isAnimateDesktop
      ? css`
          ${DesktopPage1AnimateDuration} ${DesktopTextAnimationPage01}
        `
      : ""};
`

export const PieExpDesWrapper01 = styled.div`
  width: 371px;
  font-style: normal;
  text-align: right;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
  margin-left: -720px;
  margin-top: -150px;

  @media screen and (max-width: 1024px) {
    width: 340px;
    font-size: 18px;
    margin-left: -660px;
  }

  @media screen and (max-width: 820px) {
    width: 250px;
    font-size: 14px;
    padding-left: 140px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 155px;
  }

  @media screen and (max-width: 600px) {
    width: 330px;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    margin-left: 0;
    padding-left: 0;
    margin-top: 30px;
  }

  &.animate {
    animation: ${({ isAnimateDesktop }) =>
      isAnimateDesktop
        ? css`
            ${DesktopPage1AnimateDuration} ${PieExpDesWrapper01AnimationPage02}
          `
        : ""};
  }
`

export const PieExpDesWrapper02 = styled.div`
  width: 387px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
  padding-left: 720px;
  margin-top: -80px;

  @media screen and (max-width: 1024px) {
    width: 350px;
    font-size: 18px;
    padding-left: 660px;
  }

  @media screen and (max-width: 820px) {
    width: 250px;
    font-size: 14px;
    padding-left: 514px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 495px;
  }

  @media screen and (max-width: 600px) {
    width: 330px;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    margin-left: 0;
    padding-left: 0;
    margin-top: 30px;
  }

  &.animate {
    animation: ${({ isAnimateDesktop }) =>
      isAnimateDesktop
        ? css`
            ${DesktopPage1AnimateDuration} ${PieExpDesWrapper02AnimationPage02}
          `
        : ""};
  }
`

export const PieProjectExploreTextStyled = styled.div`
  width: 317px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: right;
  color: #000000;
  margin-left: -320px;
  margin-top: 50px;

  @media screen and (max-width: 600px) {
    width: 330px;
    font-size: 18px;
    line-height: 22px;
    margin-left: 0;
    margin-top: 30px;
    text-align: center;
  }

  &.animate {
    animation: ${({ isAnimateDesktop }) =>
      isAnimateDesktop
        ? css`
            ${DesktopPage1AnimateDuration} ${DesktopFooterAnimationLeft}
          `
        : ""};
  }
`

export const PieAboutMeTextStyled = styled.div`
  width: 341px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: left;
  color: #000000;
  margin-left: 350px;
  margin-top: 50px;

  @media screen and (max-width: 600px) {
    width: 330px;
    font-size: 18px;
    line-height: 22px;
    margin-left: 0;
    margin-top: 30px;
    text-align: center;
  }

  &.animate {
    animation: ${({ isAnimateDesktop }) =>
      isAnimateDesktop
        ? css`
            ${DesktopPage1AnimateDuration} ${DesktopFooterAnimationRight}
          `
        : ""};
  }
`

export const RoseColor = styled.span`
  color: #e79c98;
`

export const PieNameText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: #00818a;

  @media screen and (max-width: 820px) {
    font-size: 24px;
    margin-top: 5px;
  }

  animation: ${(props) =>
    props.isAnimateDesktop
      ? css`
          ${DesktopPage1AnimateDuration} ${DesktopTextAnimationPage01}
        `
      : ""};
`

export const PieNormalTextStyled = styled.div`
  font-style: normal;
  width: ${(props) => (props.width ? `${props.width}px;` : "100%")};
  font-family: ${(props) => (props.Lato ? "Lato, sans-serif;" : "Source Code Pro, sans-serif")};
  font-size: ${(props) => (props.size ? `${props.size}px;` : "20px")};
  line-height: ${(props) => (props.lineHeight ? `${props.lineHeight}px;` : "25px")};
  font-weight: ${(props) => (props.weight ? `${props.weight};` : "400")};
  color: ${(props) => (props.color ? props.color : "#000")};
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : "0px")};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};

  @media screen and (max-width: 600px) {
    width: 330px;
    font-size: 18px;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
  }

  &.animate {
    animation: ${({ isAnimateDesktop }) =>
      isAnimateDesktop
        ? css`
            ${DesktopPage1AnimateDuration} ${DesktopTextAnimationPage02}
          `
        : ""};
  }
`

export const AgreementTextStyled = styled.div`
  font-style: normal;
  width: ${(props) => (props.width ? `${props.width}px;` : "100%")};
  font-family: ${(props) => (props.Lato ? "Lato, sans-serif;" : "Source Code Pro, sans-serif")};
  font-size: ${(props) => (props.size ? `${props.size}px;` : "20px")};
  line-height: ${(props) => (props.lineHeight ? `${props.lineHeight}px;` : "25px")};
  font-weight: ${(props) => (props.weight ? `${props.weight};` : "400")};
  color: ${(props) => (props.color ? props.color : "#000")};
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : "0px")};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};

  @media screen and (max-width: 820px) {
    width: 306px;
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    width: 292px;
  }

  @media screen and (max-width: 600px) {
    width: 329px;
  }
`

export const DownloadResumeLink = styled.a`
  font-style: normal;
  line-height: 25px;
  font-size: ${(props) => (props.size ? `${props.size}px;` : "20px")};
  font-weight: ${(props) => (props.weight ? `${props.weight};` : "400")};
  color: ${(props) => (props.color ? props.color : "#000")};
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : "0px")};
  text-decoration: none;

  @media screen and (max-width: 600px) {
    width: 330px;
    font-size: 18px;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
  }

  &.animate {
    animation: ${({ isAnimateDesktop }) =>
      isAnimateDesktop
        ? css`
            ${DesktopPage1AnimateDuration} ${DesktopTextAnimationPage02}
          `
        : ""};
  }
`

export const HelloText = styled.div`
  font-family: Kanit, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 101px;
  color: #000000;

  @media screen and (max-width: 820px) {
    font-size: 60px;
    line-height: 60px;
  }

  animation: ${(props) =>
    props.isAnimateDesktop
      ? css`
          ${DesktopPage1AnimateDuration} ${DesktopTextAnimationPage01}
        `
      : ""};
`

export const MobileButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MobileButtonOverrideWidth = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : "240px")};
`
