import React from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import {
  ContentTextNormal,
  BackIconWrapper,
  ProjectTextTitle,
  ContentTextBold,
  ProjectImageWrapper,
  ProjectDetailContainerStyled,
  Gap,
  FullImageWrapper,
} from "../../../components/shared/shared"

import backIcon from "../../../img/back-icon.svg"
import seniorFemaleTeacherImg from "../../../img/jordi/senior-female-teacher.png"
import stickyNoteImg from "../../../img/jordi/sticky-note.png"
import jordiDiagramImg from "../../../img/jordi/diagram.png"
import outcomeImg01 from "../../../img/jordi/outcome-01.png"
import outcomeImg02 from "../../../img/jordi/outcome-02.png"
import clockFaceImg01 from "../../../img/jordi/clock-face-01.png"
import clockFaceImg02 from "../../../img/jordi/clock-face-02.png"
import jordiAndroidPreferenceImg from "../../../img/jordi/android-preference-02.png"
import jordiProcessImage from "../../../img/jordi/doing-feeling-thinking.png"
import jordiLogo from "../../../img/jordi/jordi-logo.png"
import jordiPoster from "../../../img/jordi/jordi-poster.png"

const ContentWrapper = styled.div`
  width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 140px);
    padding: 0 70px;
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 52px);
    padding: 0 27px;
  }
`

const MethodologyWithImagesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${(props) => (props.gap ? props.gap : "50px")};

  @media screen and (max-width: 1024px) {
    align-items: center;
    flex-direction: column;
  }
`

const LeftRightImageWrapper = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  display: flex;
  flex-direction: row;
  gap: ${(props) => (props.gap ? props.gap : "50px")};

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  #clickFaceWrapperID {
    width: 50%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  #jordiAndroidPreferenceID {
    width: 50%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
`

const MethodologyWrapper = styled.div`
  width: 59%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    margin-top: -20px;
    width: 100%;
  }
`

const ImagesWrapper = styled.div`
  width: 41%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`

const ClockFaceImagesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const JordiLogoContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px;

  @media screen and (max-width: 767px) {
    flex-direction: column;

    #logoNameID {
      margin-top: 10px;
    }

    #logoContentID {
      margin-top: -30px;
    }
  }
`

const Jordi = () => {
  const navigate = useNavigate()

  return (
    <>
      <BackIconWrapper onClick={() => navigate(-1)}>
        <img src={backIcon} width={8} height={15} alt="Back Icon" />
        <ContentTextNormal>Projects</ContentTextNormal>
      </BackIconWrapper>
      <ProjectDetailContainerStyled>
        <ContentWrapper>
          <ProjectTextTitle>Jordi: Design for one | 2019</ProjectTextTitle>
          <ContentTextNormal>
            Designing for one, through sets of user interfaces that are tailored for a real person with particular
            needs, wants, and desires. Incorporating multiple types of context to create an AI-enabled Companion
            Technology.
          </ContentTextNormal>
          <ContentTextBold>
            Project Contributors: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>
              Kathleen Black, Yongqifang Hu, Jun Zhao, Zhuorong Yuan, Jiajin Lan and Juancho Larrazábal{" "}
            </ContentTextNormal>
          </ContentTextBold>
          <MethodologyWithImagesWrapper>
            <ImagesWrapper>
              <ProjectImageWrapper src={seniorFemaleTeacherImg} alt="Senior Female Teacher Image" />
              <ProjectImageWrapper src={stickyNoteImg} alt="Sticky Note Image" />
            </ImagesWrapper>
            <MethodologyWrapper>
              <ContentTextBold>Methodology</ContentTextBold>
              <Gap height="20" />
              <ContentTextNormal>
                Our approach was to study the user's daily routines through primary research. We implemented methods of
                observation, diary study, and interviews directly with the user
              </ContentTextNormal>
              <Gap height="30" />
              <ContentTextBold>User</ContentTextBold>
              <Gap height="20" />
              <ContentTextBold color="#00818A" weight="400">
                Jean Grey
              </ContentTextBold>
              <Gap height="20" />
              <ContentTextNormal>
                A professor, and mother who struggles with her busy schedule and time management.
              </ContentTextNormal>
              <Gap height="30" />
              <ContentTextBold>Opportunity Spaces</ContentTextBold>
              <Gap height="20" />
              <ContentTextBold color="#00818A" weight="400">
                Remember things she usually forgets.
              </ContentTextBold>
              <Gap height="20" />
              <ContentTextNormal>
                We identified that she was very forgetful in her daily life. Because of this, she is often having to
                turn around and rearrange her schedule.
              </ContentTextNormal>
              <Gap height="30" />
              <ContentTextBold>Design Outcome: Smartwatch</ContentTextBold>
              <Gap height="20" />
              <ContentTextNormal>
                A smartwatch is the technology device that we want to design to best aid in assisting our user’s needs,
                something she could wear and use every day.
              </ContentTextNormal>
              <Gap height="20" />
              <ContentTextNormal>
                This flow chart shows the interaction within the application and the watch, and also the connection
                between them.
              </ContentTextNormal>
            </MethodologyWrapper>
          </MethodologyWithImagesWrapper>
          <Gap height="10" />
        </ContentWrapper>
        <FullImageWrapper>
          <ProjectImageWrapper src={jordiDiagramImg} alt="Jordi Diagram" />
        </FullImageWrapper>
        <Gap height="15" />
        <ContentWrapper>
          <ContentTextBold>Outcome</ContentTextBold>
        </ContentWrapper>
        <Gap height="25" />
        <LeftRightImageWrapper gap="0">
          <ProjectImageWrapper src={outcomeImg01} alt="Pie Jordi Outcome 01" />
          <ProjectImageWrapper src={outcomeImg02} alt="Pie Jordi Outcome 02" />
        </LeftRightImageWrapper>
        <Gap height="25" />
        <ContentWrapper>
          <ContentTextBold>Interfaces</ContentTextBold>
        </ContentWrapper>
        <Gap height="30" />
        <LeftRightImageWrapper gap="0" width="80%">
          <ClockFaceImagesWrapper id="clickFaceWrapperID">
            <ProjectImageWrapper src={clockFaceImg01} alt="Pie Jordi Clock Face 01" />
            <ProjectImageWrapper src={clockFaceImg02} alt="Pie Jordi Clock Face 02" />
          </ClockFaceImagesWrapper>
          <ProjectImageWrapper
            id="jordiAndroidPreferenceID"
            src={jordiAndroidPreferenceImg}
            alt="Pie Jordi Android App Preference"
          />
        </LeftRightImageWrapper>
        <Gap height="25" />
        <ContentWrapper>
          <ContentTextBold>Jean Grey’s Experience Map</ContentTextBold>
        </ContentWrapper>
        <Gap height="30" />
        <FullImageWrapper>
          <ProjectImageWrapper src={jordiProcessImage} alt="Pie Jordi Doing Feeling Thinking Process" />
        </FullImageWrapper>
        <Gap height="25" />
        <ContentWrapper>
          <ContentTextBold>Jordi - Branding</ContentTextBold>
        </ContentWrapper>
        <Gap height="30" />
        <ProjectImageWrapper width="50%" src={jordiLogo} alt="Pie Jordi Logo" />
        <ContentWrapper>
          <JordiLogoContentWrapper>
            <ContentTextBold id="logoNameID">
              Why Jordi? <Gap height="20" />
              <ContentTextNormal>
                We identified that she was very forgetful in her daily life. Because of this, she is often having to
                turn around and rearrange her schedule.
              </ContentTextNormal>
            </ContentTextBold>
            <ContentTextBold id="logoContentID">
              Logo <Gap height="20" />
              <ContentTextNormal>
                The letter J represents Jordi and Jeans, and the letter i represents AI Technology as it referred to the
                companionship.
              </ContentTextNormal>
            </ContentTextBold>
          </JordiLogoContentWrapper>
        </ContentWrapper>
        <Gap height="30" />
        <FullImageWrapper>
          <ProjectImageWrapper src={jordiPoster} alt="Pie Jordi Poster" />
        </FullImageWrapper>
      </ProjectDetailContainerStyled>
    </>
  )
}

export default Jordi
