import React from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import {
  ContentTextNormal,
  BackIconWrapper,
  ProjectTextTitle,
  ContentTextBold,
  ProjectImageWrapper,
  ProjectDetailContainerStyled,
  FullImageWrapper,
  Gap,
} from "../../../components/shared/shared"

import backIcon from "../../../img/back-icon.svg"
import realEstateIndustryOverview from "../../../img/rsp-rental-industry/industry-overview.png"
import usEstateImage01 from "../../../img/rsp-rental-industry/us-real-estate-01.png"
import usEstateImage02 from "../../../img/rsp-rental-industry/us-real-estate-02.png"

const ContentWrapper = styled.div`
  width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 140px);
    padding: 0 70px;
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 52px);
    padding: 0 27px;
  }
`

const RentSpreeRentalIndustry = () => {
  const navigate = useNavigate()

  return (
    <>
      <BackIconWrapper onClick={() => navigate(-1)}>
        <img src={backIcon} width={8} height={15} alt="Back Icon" />
        <ContentTextNormal>Projects</ContentTextNormal>
      </BackIconWrapper>
      <ProjectDetailContainerStyled>
        <ContentWrapper>
          <ProjectTextTitle>RentSpree: US Real Estate Industry Infographic (2020)</ProjectTextTitle>
          <ContentTextNormal>
            Since RentSpree has two locations: Los Angeles and Bangkok, this project is made to educate people to
            understand how the rental industry works in the US. It is beneficial for onboarding new team members.
          </ContentTextNormal>
          {/* <ContentTextBold>
            Project Contributors: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>Yongqifang Hu, Jun Zhao, and Jiajin Lan</ContentTextNormal>
          </ContentTextBold> */}
          <ContentTextBold>
            Objective: &nbsp;&nbsp;&nbsp;
            <ContentTextNormal>To create infographics that are easy to understand.</ContentTextNormal>
          </ContentTextBold>
          <ContentTextBold>Outcome:</ContentTextBold>
        </ContentWrapper>
        <FullImageWrapper>
          <Gap height="20" />
          <ProjectImageWrapper
            src={realEstateIndustryOverview}
            alt="RentSpree's Real Estate Industry Overview Infographic"
          />
          <Gap height="50" />
          <ProjectImageWrapper src={usEstateImage01} alt="RentSpree's US Real Estate Info 01" />
          <Gap height="50" />
          <ProjectImageWrapper src={usEstateImage02} alt="RentSpree's US Real Estate Info 02" />
        </FullImageWrapper>
      </ProjectDetailContainerStyled>
    </>
  )
}

export default RentSpreeRentalIndustry
