import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
// import { getAnalytics } from "firebase/analytics"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDVhsG5l83UA9cK7PuSTUGk68DbrRFgHu8",
  authDomain: "pirpie.firebaseapp.com",
  projectId: "pirpie",
  storageBucket: "pirpie.appspot.com",
  messagingSenderId: "934609215226",
  appId: "1:934609215226:web:2bbf387bb014ed4f4bf343",
  measurementId: "G-7E6ZKG4T42",
}

// Initialize Firebase
// const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)
initializeApp(firebaseConfig)
// console.log("Firebase app => ", JSON.stringify(app.options, null, 2))
// console.log("Firebase analytics => ", JSON.stringify(analytics, null, 2))

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
