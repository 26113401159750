export const PROJECT_PATHS_NAME = [
  "/project/exploration",
  "/project/plant-riverside",
  "/project/hybridge",
  "/project/jordi",
  "/project/toe",
  "/project/service-design-experiment",
  "/project/rsp-rental-industry",
  "/project/rsp-renter-insurance",
  "/project/rsp-personas",
  "/project/athena",
  "/project/domo",
  "/project/rsp-insurance-research",
]
