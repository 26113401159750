import styled, { css } from "styled-components"

import { TextLeftAnimation, TextRightAnimation } from "./v2-animation-styles"

import { DesktopPage1AnimateDuration } from "../about/v2-constants"

export const PieNameStyled = styled.div`
  width: 255px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 31px;
  color: #00818a;
  text-align: right;
  margin-left: -240px;
  margin-top: 50px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 22px;
    margin-left: 0;
    margin-top: 30px;
    text-align: center;
  }

  animation: ${({ isAnimateDesktop }) =>
    isAnimateDesktop
      ? css`
          ${DesktopPage1AnimateDuration} ${TextLeftAnimation}
        `
      : ""};
`

export const PieExpStyled = styled.div`
  width: 341px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: right;
  color: #000000;
  margin-left: -340px;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    font-size: 18px;
    line-height: 23px;
    margin-left: 0px;
    text-align: left;
  }

  animation: ${({ isAnimateDesktop }) =>
    isAnimateDesktop
      ? css`
          ${DesktopPage1AnimateDuration} ${TextLeftAnimation}
        `
      : ""};
`

export const RentSpreeLinkStyled = styled.a`
  color: #000;
`

export const LookingForStyled = styled.div`
  width: 354px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #00818a;
  text-align: left;
  margin-left: 360px;
  margin-top: 40px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    font-size: 18px;
    line-height: 23px;
    margin-left: 0px;
    text-align: left;
    margin-top: 20px;
  }

  animation: ${({ isAnimateDesktop }) =>
    isAnimateDesktop
      ? css`
          ${DesktopPage1AnimateDuration} ${TextRightAnimation}
        `
      : ""};
`

export const DownloadResumeContactStyled = styled.a`
  font-style: normal;
  line-height: 25px;
  font-size: ${(props) => (props.size ? `${props.size}px;` : "20px")};
  font-weight: ${(props) => (props.weight ? `${props.weight};` : "400")};
  color: ${(props) => (props.color ? props.color : "#000")};
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : "0px")};
  text-decoration: none;
  /* text-align: left; */
  margin-left: 250px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    margin-left: 220px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    font-size: 18px;
    line-height: 23px;
    margin-left: 0px;
    text-align: left;
  }

  animation: ${({ isAnimateDesktop }) =>
    isAnimateDesktop
      ? css`
          ${DesktopPage1AnimateDuration} ${TextRightAnimation}
        `
      : ""};
`

export const ReachOutTomeStyled = styled.div`
  width: 324px;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 38px;
  text-align: right;
  color: #000000;
  margin-left: -320px;
  margin-top: 40px;

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    line-height: 33px;
    margin-left: 0px;
    text-align: left;
    margin-top: 40px;
  }

  &.animate {
    animation: ${({ isAnimateDesktop }) =>
      isAnimateDesktop
        ? css`
            ${DesktopPage1AnimateDuration} ${TextLeftAnimation}
          `
        : ""};
  }
`

export const ContactWayStyled = styled.div`
  font-family: Lato, sans-serif;
  margin-left: 190px;
  margin-top: 40px;

  @media screen and (max-width: 600px) {
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    margin-left: 0px;
    margin-top: 20px;
  }

  &.animate {
    animation: ${({ isAnimateDesktop }) =>
      isAnimateDesktop
        ? css`
            ${DesktopPage1AnimateDuration} ${TextRightAnimation}
          `
        : ""};
  }
`

export const ContactDetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => (props.gap ? `${props.gap}px` : "10px")};
  margin-bottom: 10px;
`
