import React from "react"
import { useNavigate } from "react-router-dom"

import { BulletWrapper } from "../../components/shared/shared"
import { Button } from "../../components/button/button"
import {
  ContentWrapper,
  ProjectTeaserWrapper,
  ProjectTeaserLeftWrapper,
  TeaserImageWrapper,
  ProjectTeaserRightWrapper,
  PieBodyImageWrapper,
  PieLegImageWrapper,
  ArrowWrapper,
  PieFaceImageWrapper,
  PieDesWrapper,
  PieExpWrapper,
  PieExpDesWrapper01,
  PieExpDesWrapper02,
  PieProjectExploreTextStyled,
  PieAboutMeTextStyled,
  RoseColor,
  PieNameText,
  PieNormalTextStyled,
  AgreementTextStyled,
  DownloadResumeLink,
  HelloText,
  MobileButtonWrapper,
  MobileButtonOverrideWidth,
} from "../../components/about/v2-styles"
import {
  PieLegAnimationWrapper,
  PieBodyAnimationWrapper,
  PieFaceAnimationWrapper,
} from "../../components/about/v2-animation-styles"

import PieBodyImage from "../../img/about-v2/pie-body-image.png"
import PieLegImage from "../../img/about-v2/pie-leg-image.png"
import ArrowIcon from "../../img/about-v2/arrow-icon.png"
import PieFaceImage from "../../img/about-v2/pie-face-image.png"
import DownloadIcon from "../../img/download-icon.svg"
import DomoAppBgImage from "../../img/about-v2/domo-app-bg.png"
import HybridgeBgImage from "../../img/about-v2/hybridge-bg.png"
import RenterInsuranceBgImage from "../../img/about-v2/renter-insurance-bg.png"
import CoFarmImage from "../../img/about-v2/cofarm-main-poster-img.png"
import CoFarmMobileImage from "../../img/about-v2/cofarm-poster-mobile-img.png"
import DomoThemedAttractionImage from "../../img/about-v2/themed-attraction-img.png"

import pieResumePdf from "../../resources/pie-prapawuttikul-resume-2022.pdf"

const ANIMATION_IDS = [
  "pie-face-img",
  "pie-exp-text-01",
  "pie-exp-text-02",
  "explore-text",
  "download-resume-text",
  "second-arrow",
  "cofarm-project-left",
  "cofarm-project-right",
  "demo-project-right",
  "demo-project-left",
  "demo-project-right",
  "hybridge-project-left",
  "hybridge-project-right",
  "ri-project-left",
  "ri-project-right",
  "third-arrow",
  "explore-footer-text",
  "view-more-project-btn",
  "get-to-know-text",
  "about-pie-btn",
]

const ANIMATION_POSITIONS = {
  "pie-face-img": 100,
  "pie-exp-text-01": 28,
  "pie-exp-text-02": 28,
  "explore-text": 35,
  "download-resume-text": 35,
  "second-arrow": 35,
  "cofarm-project-left": 130,
  "cofarm-project-right": 130,
  "demo-project-left": 130,
  "demo-project-right": 130,
  "hybridge-project-left": 130,
  "hybridge-project-right": 130,
  "ri-project-left": 130,
  "ri-project-right": 130,
  "third-arrow": 130,
  "explore-footer-text": 50,
  "view-more-project-btn": 50,
  "get-to-know-text": 50,
  "about-pie-btn": 50,
}

const HELLO_TEXT_LIST = ["Hello !", "สวัสดี", "Ciao", "Hola", "नमस्ते", "Bonjour", "안녕", "你好"]
// const HELLO_TEXT_LIST = ["สวัสดี"]

const AboutV2 = () => {
  const navigate = useNavigate()

  const animation = () => {
    for (var i = 0; i < ANIMATION_IDS.length; i++) {
      var component = document.getElementById(ANIMATION_IDS[i])
      var windowHeight = window.innerHeight
      var elementTop = component.getBoundingClientRect().top
      var elementVisible = ANIMATION_POSITIONS[ANIMATION_IDS[i]]
      // if (i === 13) {
      //   console.log("windowHeight ", windowHeight)
      //   console.log("elementTop ", elementTop)
      //   console.log("elementTop < windowHeight - elementVisible ", elementTop < windowHeight - elementVisible)
      // }
      if (elementTop < windowHeight - elementVisible) {
        component.classList.add("animate")
      } else {
        component.classList.remove("animate")
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", animation)
    return () => {
      window.removeEventListener("scroll", animation)
    }
  }, [])

  React.useEffect(() => {
    const helloComp = document.getElementById("hello-text")
    let counter = 0
    const helloTextInterval = setInterval(() => {
      // TODO: Handle only hello Thai by using Kanit
      // if (counter === 1) {
      //   helloComp.style.fontFamily = "Kanit, sans-serif"
      // }
      helloComp.innerHTML = HELLO_TEXT_LIST[counter]
      if (counter === HELLO_TEXT_LIST.length - 1) counter = 0
      else counter++
    }, 1000)

    return () => clearInterval(helloTextInterval)
  }, [])

  const MobileComponent = () => (
    <ContentWrapper showMobile hideDesktop>
      <HelloText id="hello-text">Hello!</HelloText>
      <PieNameText>My name is Pie :)</PieNameText>
      <PieBodyImageWrapper src={PieBodyImage} alt="Pie's Image 02" showMobile />
      <PieDesWrapper>I am a US based Product / Service Designer</PieDesWrapper>
      <PieExpWrapper>
        I build <RoseColor>pixel-perfect</RoseColor> digital products, brands, and <RoseColor>seamless</RoseColor>{" "}
        experience with <RoseColor>passion</RoseColor>.
      </PieExpWrapper>
      <ArrowWrapper src={ArrowIcon} alt="Pie First Arrow" mt="30" />
      <PieFaceImageWrapper src={PieFaceImage} alt="Pie's Face Image" />
      <PieExpDesWrapper01>From graphic designer to product designer, and everything in between</PieExpDesWrapper01>
      <PieExpDesWrapper02>Love to wear many hats and excited to all the upcoming challenges!</PieExpDesWrapper02>
      <PieNormalTextStyled mt="40" color="#00818A" textAlign="center">
        Explore my latest works ;)
      </PieNormalTextStyled>
      <DownloadResumeLink mt="10" href={pieResumePdf} target="_blank">
        Or <img src={DownloadIcon} alt="Download Icon" /> Download my resume
      </DownloadResumeLink>
      <ArrowWrapper src={ArrowIcon} alt="Pie First Arrow" mt="30" />

      <TeaserImageWrapper height="288" mt="50" src={CoFarmMobileImage} alt="CoFarm Mobile Image" />
      <ProjectTeaserRightWrapper width="auto" bg="#E8D2C3">
        <PieNormalTextStyled color="#000" size="24" lineHeight="30">
          2022
        </PieNormalTextStyled>
        <PieNormalTextStyled color="#000" size="20" lineHeight="24" weight="700" mt="10" Lato>
          CoFarm: Agritourism Application Design
        </PieNormalTextStyled>
        <BulletWrapper mt="20">
          <li style={{ color: "#000" }}>
            <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
              User research; survey and interviews
            </PieNormalTextStyled>
          </li>
          <li style={{ color: "#000" }}>
            <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
              Service Design
            </PieNormalTextStyled>
          </li>
          <li style={{ color: "#000" }}>
            <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
              Application Design
            </PieNormalTextStyled>
          </li>
          <li style={{ color: "#000" }}>
            <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
              Prototyping and usabiity testing
            </PieNormalTextStyled>
          </li>
          <li style={{ color: "#000" }}>
            <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
              Business Model Canvas
            </PieNormalTextStyled>
          </li>
        </BulletWrapper>
        <MobileButtonWrapper>
          <MobileButtonOverrideWidth>
            <Button
              Lato
              width="240"
              bgColor="#fff"
              color="#000"
              fontSize="18"
              mFontSize="16"
              weight="400"
              mt="30"
              border="1px solid #000000"
              onClick={() => navigate("/project/cofarm")}>
              View Project
            </Button>
          </MobileButtonOverrideWidth>
        </MobileButtonWrapper>
      </ProjectTeaserRightWrapper>

      <TeaserImageWrapper height="288" mt="50" src={DomoAppBgImage} alt="Pie Domo App" />
      <ProjectTeaserRightWrapper width="auto">
        <PieNormalTextStyled color="#fff" size="24" lineHeight="30">
          2022
        </PieNormalTextStyled>
        <PieNormalTextStyled color="#fff" size="20" lineHeight="24" weight="700" mt="10" Lato>
          Domo: Travel to home from home
        </PieNormalTextStyled>
        <BulletWrapper mt="20">
          <li style={{ color: "#fff" }}>
            <PieNormalTextStyled Lato color="#fff" size="18" lineHeight="22" weight="300">
              An immersive marketplace platform
            </PieNormalTextStyled>
          </li>
          <li style={{ color: "#fff" }}>
            <PieNormalTextStyled Lato color="#fff" size="18" lineHeight="22" weight="300">
              User flows and interfaces design for mobile application
            </PieNormalTextStyled>
          </li>
          <li style={{ color: "#fff" }}>
            <PieNormalTextStyled Lato color="#fff" size="18" lineHeight="22" weight="300">
              Themed Entertainment design
            </PieNormalTextStyled>
          </li>
        </BulletWrapper>
        <PieNormalTextStyled Lato color="#fff" size="18" lineHeight="22" weight="300">
          Themed Attraction Student Showcase
        </PieNormalTextStyled>
        <PieNormalTextStyled Lato color="#fff" size="18" lineHeight="22" weight="600">
          2022: Best in Class Award
        </PieNormalTextStyled>
        <MobileButtonWrapper>
          <MobileButtonOverrideWidth>
            <Button
              Lato
              width="240"
              bgColor="#fff"
              color="#000"
              fontSize="18"
              mFontSize="16"
              weight="400"
              mt="30"
              onClick={() => navigate("/project/domo")}>
              View Project
            </Button>
          </MobileButtonOverrideWidth>
        </MobileButtonWrapper>
      </ProjectTeaserRightWrapper>

      <TeaserImageWrapper height="222" mt="30" src={HybridgeBgImage} alt="Pie Hybridge App" objectFit="contain" />
      <ProjectTeaserRightWrapper width="auto" bg="#fff">
        <PieNormalTextStyled color="#000" size="24" lineHeight="30">
          2021
        </PieNormalTextStyled>
        <PieNormalTextStyled color="#000" size="20" lineHeight="24" weight="700" mt="10" Lato>
          Hybridge: Connecting the future’s professionals
        </PieNormalTextStyled>
        <BulletWrapper mt="20">
          <li style={{ color: "#000" }}>
            <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
              Industry research; secondary, trends research
            </PieNormalTextStyled>
          </li>
          <li style={{ color: "#000" }}>
            <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
              User research; survey and interviews
            </PieNormalTextStyled>
          </li>
          <li style={{ color: "#000" }}>
            <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
              Prototyping and usabiity testing
            </PieNormalTextStyled>
          </li>
          <li style={{ color: "#000" }}>
            <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
              Web design
            </PieNormalTextStyled>
          </li>
          <li style={{ color: "#000" }}>
            <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
              Business Model Canvas
            </PieNormalTextStyled>
          </li>
        </BulletWrapper>
        <MobileButtonWrapper>
          <MobileButtonOverrideWidth>
            <Button
              Lato
              width="240"
              bgColor="#80BCD6"
              color="#000"
              fontSize="18"
              mFontSize="16"
              weight="400"
              mt="30"
              onClick={() => navigate("/project/hybridge")}>
              View Project
            </Button>
          </MobileButtonOverrideWidth>
        </MobileButtonWrapper>
      </ProjectTeaserRightWrapper>

      <TeaserImageWrapper
        mt="30"
        height="260"
        src={RenterInsuranceBgImage}
        alt="RentSpree Renter Insurance"
        objectFit="contain"
        bg="#00818A"
      />
      <ProjectTeaserRightWrapper mt="-20" width="auto" bg="#00818A">
        <PieNormalTextStyled color="#fff" size="30" lineHeight="38">
          2021
        </PieNormalTextStyled>
        <PieNormalTextStyled color="#fff" size="30" lineHeight="36" weight="700" mt="0" Lato>
          RentSpree: Renters Insurance 1.0
        </PieNormalTextStyled>
        <BulletWrapper mt="10">
          <li style={{ color: "#fff" }}>
            <PieNormalTextStyled Lato color="#fff" size="18" lineHeight="22" weight="300">
              User flows and interfaces design
            </PieNormalTextStyled>
          </li>
          <li style={{ color: "#fff" }}>
            <PieNormalTextStyled Lato color="#fff" size="18" lineHeight="22" weight="300">
              Prototyping and usability testing
            </PieNormalTextStyled>
          </li>
          <li style={{ color: "#fff" }}>
            <PieNormalTextStyled Lato color="#fff" size="18" lineHeight="22" weight="300">
              Design experiment, goal, and hypothesis
            </PieNormalTextStyled>
          </li>
        </BulletWrapper>
        <AgreementTextStyled Lato color="#fff" size="18" lineHeight="22" weight="300" mt="20" width="329">
          The password is required to view this project due to the{" "}
          <span style={{ fontWeight: "500" }}>Non-discloser Agreement.</span> Please enter the password or contact
          pirpiep@gmail.com for inquiries.
        </AgreementTextStyled>
        <MobileButtonWrapper>
          <MobileButtonOverrideWidth>
            <Button
              Lato
              width="240"
              bgColor="#fff"
              color="#00818A"
              fontSize="18"
              mFontSize="16"
              weight="400"
              mt="30"
              onClick={() => navigate("/project/rsp-renter-insurance")}>
              View Project
            </Button>
          </MobileButtonOverrideWidth>
        </MobileButtonWrapper>
      </ProjectTeaserRightWrapper>
      <ArrowWrapper src={ArrowIcon} alt="Pie First Arrow" mt="30" />
      <PieProjectExploreTextStyled>There are a lot more projects to explore here</PieProjectExploreTextStyled>
      <MobileButtonWrapper>
        <MobileButtonOverrideWidth width="200">
          <Button
            Lato
            width="200"
            bgColor="#00818A"
            color="#fff"
            fontSize="18"
            mFontSize="16"
            weight="400"
            mt="20"
            ml="0"
            onClick={() => navigate("/projects")}>
            View more projects
          </Button>
        </MobileButtonOverrideWidth>
      </MobileButtonWrapper>
      <PieAboutMeTextStyled>
        Or <br /> get to know more about me, reach out to me, and say hi! <br />
        <br /> I will be waiting for you ;)
      </PieAboutMeTextStyled>
      <MobileButtonWrapper>
        <MobileButtonOverrideWidth width="140">
          <Button
            Lato
            width="140"
            bgColor="#80BCD6"
            color="#fff"
            fontSize="18"
            mFontSize="16"
            weight="400"
            mt="20"
            ml="0"
            onClick={() => navigate("/contact")}>
            About Pie :)
          </Button>
        </MobileButtonOverrideWidth>
      </MobileButtonWrapper>
    </ContentWrapper>
  )

  const DesktopComponent = () => (
    <>
      <PieLegAnimationWrapper isAnimateDesktop>
        <PieLegImageWrapper src={PieLegImage} alt="Pie's Image 01" hideMobile />
      </PieLegAnimationWrapper>
      <PieBodyAnimationWrapper isAnimateDesktop>
        <PieBodyImageWrapper src={PieBodyImage} alt="Pie's Image 02" hideMobile />
      </PieBodyAnimationWrapper>
      <ContentWrapper hideMobile>
        <HelloText id="hello-text" isAnimateDesktop>
          Hello !
        </HelloText>
        <PieNameText isAnimateDesktop>My name is Pie :)</PieNameText>
        <PieDesWrapper isAnimateDesktop>I am a US based Product / Service Designer</PieDesWrapper>
        <PieExpWrapper isAnimateDesktop>
          I build <RoseColor>pixel-perfect</RoseColor> digital products, brands, and <RoseColor>seamless</RoseColor>{" "}
          experience with <RoseColor>passion</RoseColor>.
        </PieExpWrapper>
        <ArrowWrapper isAnimateDesktop firstArrow src={ArrowIcon} alt="Pie First Arrow" mt="-60" />
        <PieFaceAnimationWrapper id="pie-face-img" isAnimateDesktop>
          <PieFaceImageWrapper src={PieFaceImage} alt="Pie's Face Image" />
        </PieFaceAnimationWrapper>
        <PieExpDesWrapper01 id="pie-exp-text-01" isAnimateDesktop>
          From graphic designer to product designer, and everything in between
        </PieExpDesWrapper01>
        <PieExpDesWrapper02 id="pie-exp-text-02" isAnimateDesktop>
          Love to wear many hats and excited to all the upcoming challenges!
        </PieExpDesWrapper02>
        <PieNormalTextStyled id="explore-text" isAnimateDesktop mt="150" color="#00818A" textAlign="center">
          Explore my latest works ;)
        </PieNormalTextStyled>
        <DownloadResumeLink id="download-resume-text" isAnimateDesktop mt="20" href={pieResumePdf} target="_blank">
          Or <img src={DownloadIcon} alt="Download Icon" /> Download my resume
        </DownloadResumeLink>
        <ArrowWrapper id="second-arrow" isAnimateDesktop secondArrow src={ArrowIcon} alt="Pie Second Arrow" mt="80" />

        <ProjectTeaserWrapper mt="80">
          <ProjectTeaserRightWrapper width="32%" bg="#E6CDBF" id="cofarm-project-right" isAnimateDesktop animateLeft>
            <PieNormalTextStyled color="#000" size="30" lineHeight="38">
              2022
            </PieNormalTextStyled>
            <PieNormalTextStyled width="443" color="#000" size="30" lineHeight="36" weight="700" mt="20" Lato>
              CoFarm: Agritourism Application Design
            </PieNormalTextStyled>
            <BulletWrapper mt="20">
              <li style={{ color: "#000" }}>
                <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
                  Industry research; secondary, trends research
                </PieNormalTextStyled>
              </li>
              <li style={{ color: "#000" }}>
                <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
                  User research; survey and interviews
                </PieNormalTextStyled>
              </li>
              <li style={{ color: "#000" }}>
                <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
                  Prototyping and usabiity testing
                </PieNormalTextStyled>
              </li>
              <li style={{ color: "#000" }}>
                <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
                  Web design
                </PieNormalTextStyled>
              </li>
              <li style={{ color: "#000" }}>
                <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
                  Business Model Canvas
                </PieNormalTextStyled>
              </li>
            </BulletWrapper>
            <Button
              Lato
              width="240"
              bgColor="#fff"
              color="#000"
              fontSize="18"
              weight="400"
              mt="144"
              mt820="100"
              mt768="80"
              border="1px solid #000000"
              onClick={() => navigate("/project/cofarm")}>
              View Project
            </Button>
          </ProjectTeaserRightWrapper>
          <ProjectTeaserLeftWrapper width="68%" id="cofarm-project-left" isAnimateDesktop animateRight>
            <TeaserImageWrapper src={CoFarmImage} alt="Pie CoFarm App" />
          </ProjectTeaserLeftWrapper>
        </ProjectTeaserWrapper>

        <ProjectTeaserWrapper mt="80">
          <ProjectTeaserLeftWrapper id="demo-project-left" isAnimateDesktop>
            <TeaserImageWrapper src={DomoAppBgImage} alt="Pie Domo App" />
          </ProjectTeaserLeftWrapper>
          <ProjectTeaserRightWrapper id="demo-project-right" isAnimateDesktop>
            <PieNormalTextStyled color="#fff" size="30" lineHeight="38">
              2022
            </PieNormalTextStyled>
            <PieNormalTextStyled color="#fff" size="30" lineHeight="36" weight="700" mt="20" Lato>
              Domo: Travel to home from home
            </PieNormalTextStyled>
            <BulletWrapper mt="20">
              <li style={{ color: "#fff" }}>
                <PieNormalTextStyled Lato color="#fff" size="18" lineHeight="22" weight="300">
                  An immersive marketplace platform
                </PieNormalTextStyled>
              </li>
              <li style={{ color: "#fff" }}>
                <PieNormalTextStyled Lato color="#fff" size="18" lineHeight="22" weight="300">
                  User flows and interfaces design for mobile application
                </PieNormalTextStyled>
              </li>
              <li style={{ color: "#fff" }}>
                <PieNormalTextStyled Lato color="#fff" size="18" lineHeight="22" weight="300">
                  Themed Entertainment design
                </PieNormalTextStyled>
              </li>
            </BulletWrapper>
            <img
              src={DomoThemedAttractionImage}
              alt="Domo Themed Attraction Awarded"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            />
            <PieNormalTextStyled Lato color="#fff" size="18" lineHeight="22" weight="300">
              Themed Attraction Student Showcase 2022
            </PieNormalTextStyled>
            <PieNormalTextStyled Lato color="#fff" size="18" lineHeight="22" weight="600">
              Best in Class Award
            </PieNormalTextStyled>
            <Button
              Lato
              width="240"
              bgColor="#fff"
              color="#000"
              fontSize="18"
              weight="400"
              mt="40"
              onClick={() => navigate("/project/domo")}>
              View Project
            </Button>
          </ProjectTeaserRightWrapper>
        </ProjectTeaserWrapper>

        <ProjectTeaserWrapper mt="80">
          <ProjectTeaserRightWrapper
            width="32%"
            bg="transparent"
            id="hybridge-project-right"
            isAnimateDesktop
            animateLeft>
            <PieNormalTextStyled color="#000" size="30" lineHeight="38">
              2021
            </PieNormalTextStyled>
            <PieNormalTextStyled width="443" color="#000" size="30" lineHeight="36" weight="700" mt="20" Lato>
              Hybridge: Connecting the future’s professionals
            </PieNormalTextStyled>
            <BulletWrapper mt="20">
              <li style={{ color: "#000" }}>
                <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
                  Industry research; secondary, trends research
                </PieNormalTextStyled>
              </li>
              <li style={{ color: "#000" }}>
                <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
                  User research; survey and interviews
                </PieNormalTextStyled>
              </li>
              <li style={{ color: "#000" }}>
                <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
                  Prototyping and usabiity testing
                </PieNormalTextStyled>
              </li>
              <li style={{ color: "#000" }}>
                <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
                  Web design
                </PieNormalTextStyled>
              </li>
              <li style={{ color: "#000" }}>
                <PieNormalTextStyled Lato color="#000" size="18" lineHeight="22" weight="300">
                  Business Model Canvas
                </PieNormalTextStyled>
              </li>
            </BulletWrapper>
            <Button
              Lato
              width="240"
              bgColor="#80BCD6"
              color="#000"
              fontSize="18"
              weight="400"
              mt="144"
              mt820="100"
              mt768="80"
              onClick={() => navigate("/project/hybridge")}>
              View Project
            </Button>
          </ProjectTeaserRightWrapper>
          <ProjectTeaserLeftWrapper width="80%" id="hybridge-project-left" isAnimateDesktop animateRight>
            <TeaserImageWrapper src={HybridgeBgImage} alt="Pie Hybridge App" objectFit="contain" />
          </ProjectTeaserLeftWrapper>
        </ProjectTeaserWrapper>

        <ProjectTeaserWrapper mt="80" mt820="40" mt768="30">
          <ProjectTeaserLeftWrapper width="60%" bg="#00818A" id="ri-project-left" isAnimateDesktop>
            <TeaserImageWrapper
              height="410"
              src={RenterInsuranceBgImage}
              alt="RentSpree Renter Insurance"
              objectFit="contain"
            />
          </ProjectTeaserLeftWrapper>
          <ProjectTeaserRightWrapper width="40%" bg="#00818A" id="ri-project-right" isAnimateDesktop>
            <PieNormalTextStyled color="#fff" size="30" lineHeight="38">
              2021
            </PieNormalTextStyled>
            <PieNormalTextStyled color="#fff" size="30" lineHeight="36" weight="700" mt="20" Lato>
              RentSpree: Renters Insurance 1.0
            </PieNormalTextStyled>
            <BulletWrapper mt="20">
              <li style={{ color: "#fff" }}>
                <PieNormalTextStyled Lato color="#fff" size="18" lineHeight="22" weight="300">
                  User flows and interfaces design
                </PieNormalTextStyled>
              </li>
              <li style={{ color: "#fff" }}>
                <PieNormalTextStyled Lato color="#fff" size="18" lineHeight="22" weight="300">
                  Prototyping and usability testing
                </PieNormalTextStyled>
              </li>
              <li style={{ color: "#fff" }}>
                <PieNormalTextStyled Lato color="#fff" size="18" lineHeight="22" weight="300">
                  Design experiment, goal, and hypothesis
                </PieNormalTextStyled>
              </li>
            </BulletWrapper>
            <AgreementTextStyled Lato color="#fff" size="18" lineHeight="22" weight="300" mt="50" width="404">
              The password is required to view this project due to the{" "}
              <span style={{ fontWeight: "500" }}>Non-discloser Agreement.</span> Please enter the password or contact
              pirpiep@gmail.com for inquiries.
            </AgreementTextStyled>
            <Button
              Lato
              width="240"
              bgColor="#fff"
              color="#00818A"
              fontSize="18"
              weight="400"
              mt="50"
              onClick={() => navigate("/project/rsp-renter-insurance")}>
              View Project
            </Button>
          </ProjectTeaserRightWrapper>
        </ProjectTeaserWrapper>

        <ArrowWrapper id="third-arrow" isAnimateDesktop thirdArrow src={ArrowIcon} alt="Pie Second Arrow" mt="80" />
        <PieProjectExploreTextStyled id="explore-footer-text" isAnimateDesktop>
          There are a lot more projects to explore here
        </PieProjectExploreTextStyled>
        <Button
          id="view-more-project-btn"
          isAnimateDesktop
          animateLeft
          Lato
          width="200"
          bgColor="#00818A"
          color="#fff"
          fontSize="18"
          weight="400"
          mt="20"
          ml="-250"
          onClick={() => navigate("/projects")}>
          View more projects
        </Button>
        <PieAboutMeTextStyled id="get-to-know-text" isAnimateDesktop>
          Or <br /> get to know more about me, reach out to me, and say hi! <br />
          <br /> I will be waiting for you ;)
        </PieAboutMeTextStyled>
        <Button
          id="about-pie-btn"
          isAnimateDesktop
          Lato
          width="140"
          bgColor="#80BCD6"
          color="#fff"
          fontSize="18"
          weight="400"
          mt="20"
          ml="200"
          onClick={() => navigate("/contact")}>
          About Pie :)
        </Button>
      </ContentWrapper>
    </>
  )

  return (
    <>
      <DesktopComponent />
      <MobileComponent />
    </>
  )
}

export default AboutV2
