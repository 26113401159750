import styled, { css } from "styled-components"

import { DesktopPage1AnimateDuration } from "../about/v2-constants.js"
import { DesktopFooterAnimationLeft, DesktopFooterAnimationRight } from "../about/v2-animation-styles.js"

export const Button = styled.a`
  display: block;
  text-align: center;
  padding: 9px 25px;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px;` : "16px;")};
  font-family: ${(props) => (props.Lato ? "Lato, sans-serif;" : "Source Code Pro, sans-serif;")};
  font-weight: ${(props) => (props.weight ? `${props.weight};` : "normal;")};
  text-decoration: none;

  cursor: pointer;
  color: ${(props) => (props.color ? props.color : "#fff")};
  /* overflow: hidden; */

  background-color: ${(props) => (props.bgColor ? props.bgColor : "#00818a")};
  border-radius: 50px;
  ${(props) => props.border && `border: ${props.border}`};

  ${(props) => props.width && `width: ${props.width}px`};
  ${(props) => props.mt && `margin-top: ${props.mt}px`};
  ${(props) => props.mr && `margin-right: ${props.mr}px`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}px`};
  ${(props) => props.ml && `margin-left: ${props.ml}px`};

  ${(props) => props.boxSizing && `box-sizing: ${props.boxSizing}`};
  ${(props) => props.backdropFilter && `backdrop-filter: ${props.backdropFilter}`};

  @media screen and (max-width: 820px) {
    ${(props) => props.mt820 && `margin-top: ${props.mt820}px`};
  }

  @media screen and (max-width: 768px) {
    ${(props) => props.mt768 && `margin-top: ${props.mt768}px`};
  }

  @media screen and (max-width: 414px) {
    font-size: 14px;
    width: calc(100% - 52px);
    ${(props) => props.mFontSize && `font-size: ${props.mFontSize}px`};
    ${(props) => props.mWidth && `width: ${props.mWidth}px`};
  }

  &.animate {
    animation: ${({ isAnimateDesktop, animateLeft }) =>
      isAnimateDesktop
        ? css`
            ${DesktopPage1AnimateDuration} ${animateLeft ? DesktopFooterAnimationLeft : DesktopFooterAnimationRight}
          `
        : ""};
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => (props.gap ? `${props.gap}px` : "12px")};

  @media screen and (max-width: 768px) {
    ${(props) => props.mCenter && "justify-content: center"};
  }

  @media screen and (max-width: 414px) {
    gap: ${(props) => (props.mGap ? `${props.mGap}px` : "12px")};
    ${(props) => props.mColumn && "flex-direction: column"};
  }
`
